import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StyledTextDescribeModal, StyledTitleModal } from "./ConfirmPublishAds.modal";

interface PublishNowModalProps {
  singular: boolean;
}

const PublishedModal = ({ singular }: PublishNowModalProps) => {
  const { t } = useTranslation();

  return (
    <Stack alignItems="center" justifyContent="center" paddingY="1.5rem" spacing={2}>
      <img src="/images/illus_check.svg" alt="check" style={{ width: "48px", height: "48px" }} />
      {singular ? (
        <StyledTitleModal>{t("ads.details.modals.publish.confirm.publishedAdTitle")}</StyledTitleModal>
      ) : (
        <StyledTitleModal>{t("ads.details.modals.publish.confirm.publishedAdsTitle")}</StyledTitleModal>
      )}
      <StyledTextDescribeModal sx={{ paddingX: "1rem" }}>
        {singular ? t("ads.details.modals.publish.confirm.publishedAd") : t("ads.details.modals.publish.confirm.publishedAds")}
      </StyledTextDescribeModal>
    </Stack>
  );
};

export default PublishedModal;
