import AIOButtonComponent from "@components/Button.component";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

interface NowOrLaterPublishButtonActionProps {
  onPublishNow: () => void;
  onPublishLater: () => void;
  onCancel?: () => void;
  displayCancelButton?: boolean;
  loading: boolean;
}

const NowOrLaterPublishButtonAction = ({
  onPublishNow,
  onPublishLater,
  onCancel,
  displayCancelButton,
  loading,
}: NowOrLaterPublishButtonActionProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack direction={breakpointDownSM ? "column" : "row"} spacing={2} justifyContent="space-between">
      <AIOButtonComponent disabled={loading} variant="contained" onClick={onPublishNow} title={t("ads.details.modals.publish.confirm.publish_now")} />
      <AIOButtonComponent
        disabled={loading}
        variant="contained"
        onClick={onPublishLater}
        title={t("ads.details.modals.publish.confirm.publish_later")}
      />
      {displayCancelButton && onCancel && (
        <AIOButtonComponent disabled={loading} ariaLabel="light" onClick={onCancel} title={t("ads.details.modals.publish.confirm.cancel")} />
      )}
    </Stack>
  );
};

export default NowOrLaterPublishButtonAction;
