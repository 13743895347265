import { Colors } from "@constants/colors.constant";
import { Box, styled } from "@mui/material";
import UpdateAd from "./preview/UpdateAd.preview";

const StyledBoxContentCard = styled(Box)({
  flex: 1,
  backgroundColor: Colors.greyCardBg,
  borderRadius: "15px",
  padding: "10px 10px 0 10px",
  overflowY: "auto",
  marginTop: "10px",
});

/* <StyledBoxContentCard>
          <Box
            sx={{
              height: "100%",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "10px",
                borderRadius: "15px",
              },
            }}>
            <PreviewAdsCard />
          </Box>
        </StyledBoxContentCard> */

const CorrectAdModal = () => {
  return (
    <StyledBoxContentCard>
      <Box
        sx={{
          height: "100%",
          overflowY: "auto",
          padding: 2,
          "&::-webkit-scrollbar": {
            width: "12px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: Colors.greyCardBg,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: Colors.purple,
            borderRadius: "10px",
          },
        }}>
        <UpdateAd />
      </Box>
    </StyledBoxContentCard>
  );
};

export default CorrectAdModal;
