import { useState } from "react";
import { Box, Button, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useObservable } from "@ngneat/react-rxjs";
import { useTranslation } from "react-i18next";

import { DashboardStat, globalStatisticsQuery, globalStatisticsService } from "@store/globalStatistics";
import { EntitiesModel } from "@store/entities/entities.model";

import ActionsTimelineComponent from "@components/ActionsTimeline.component";
import LastActionsModal from "@screens/auth/client/dashboard/modals/LastActions.modal";
import DashboardCard from "@components/card/Dashboard.card";
import { StatTooltipComponent } from "@screens/auth/common/ads/Ads";
import LinkCard from "@components/LinkCard.component";
import ClientApplicationsChart from "@components/charts/ClientApplications.chart";
import AdDistributionChart from "@components/charts/AdDistribution.chart";
import AffiliateTimelineComponent from "@components/AffiliateTimeline.component";

interface AffiliateDashboardComponentProps {
  affiliate?: EntitiesModel;
}

const AffiliateDashboardComponent = (props: AffiliateDashboardComponentProps) => {
  const { affiliate } = props;

  const theme = useTheme();
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));
  const breakpointDownLG = useMediaQuery(theme.breakpoints.down("lg"));

  const { t } = useTranslation();

  const [openLastActions, setOpenLastActions] = useState<boolean>(false);

  const [{ affiliatesStats, loading }] = useObservable(globalStatisticsQuery.affiliatesStatistics$);

  return (
    <Stack
      direction="column"
      spacing={breakpointDownLG ? 3 : 5.625}
      py={breakpointDownMD ? "20px" : "30px"}
      px="5px"
      overflow="auto"
      className="scrollable"
      width="100%">
      <Box>
        <Grid container spacing={breakpointDownLG ? 1 : 3}>
          <Grid item xs={12} lg={6} xl={12 / 5}>
            <DashboardCard
              loading={loading}
              stat={DashboardStat.ONLINE_ADS}
              helper={<StatTooltipComponent title={t("dashboard.stats.onlineAds")} description={t("dashboard.stats.onlineAdsHelper")} />}
              disableClick
              value={affiliatesStats.announcementNbr ?? 0}
            />
          </Grid>
          <Grid item xs={6} lg={6} xl={12 / 5}>
            <DashboardCard
              loading={loading}
              stat={DashboardStat.QUALITY_RATE}
              helper={<StatTooltipComponent title={t("dashboard.stats.qualityRate")} description={t("dashboard.stats.qualityRateHelper")} />}
              disableClick
              extra="%"
              value={affiliatesStats.quality ? Math.round(affiliatesStats.quality * 100) : 50}
            />
          </Grid>
          <Grid item xs={6} lg={4} xl={12 / 5}>
            <DashboardCard
              loading={loading}
              stat={DashboardStat.APPLICATIONS}
              disableClick
              value={(affiliatesStats.aioApplicationNbr ?? 0) + (affiliatesStats.powerBiApplicationNbr ?? 0)}
            />
          </Grid>
          <Grid item xs={6} lg={4} xl={12 / 5}>
            <DashboardCard
              loading={loading}
              stat={DashboardStat.INEFFICIENT}
              helper={<StatTooltipComponent title={t("dashboard.stats.inefficientAds")} description={t("dashboard.stats.inefficientAdsHelper")} />}
              disableClick={!(affiliatesStats.inefficient?.length ?? 0)}
              value={affiliatesStats.inefficient?.length ?? 0}
            />
          </Grid>
          <Grid item xs={6} lg={4} xl={12 / 5}>
            <DashboardCard
              loading={loading}
              stat={DashboardStat.BOOSTS}
              disableClick={!(affiliatesStats.boosts?.length ?? 0)}
              value={affiliatesStats.boosts?.length ?? 0}
            />
          </Grid>
        </Grid>
      </Box>

      {!loading && (
        <Stack direction={breakpointDownLG ? "column" : "row"} spacing={breakpointDownLG ? 3 : 5.625}>
          <Stack direction="column" spacing={breakpointDownLG ? 3 : 5.625} width={breakpointDownLG ? "100%" : "60%"}>
            <AffiliateTimelineComponent affiliate={affiliate} />
            <ClientApplicationsChart
              getChartData={(year) => globalStatisticsService.getAffiliatesApplicationsStats(year)}
              title={t("charts.advertisingAudience")}
            />
            <Stack direction="column">
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography fontSize={20} fontWeight={700}>
                  {t("dashboard.actions.lastActions", { count: (affiliatesStats.lastActions ?? []).length })}
                </Typography>
                {(affiliatesStats.lastActions ?? []).length > 3 && <Button onClick={() => setOpenLastActions(true)}>{t("dashboard.seeAll")}</Button>}
              </Stack>
              <ActionsTimelineComponent actions={(affiliatesStats.lastActions ?? []).slice(0, 3)} />
            </Stack>
          </Stack>

          <Stack direction="column" spacing={breakpointDownLG ? 3 : 5.625} width={breakpointDownLG ? "100%" : "35%"}>
            <AdDistributionChart
              getChartData={() => globalStatisticsService.getAffiliatesApplicationsDistribution()}
              totalApplications={affiliatesStats.powerBiApplicationNbr ?? 0}
              title={t("charts.adDistribution")}
            />
            <Stack spacing="15px">
              <LinkCard
                link={process.env.REACT_APP_PUBLIC_TRELLO_LINK || ""}
                icon={<img src="/images/news.svg" alt="news" />}
                text={t("dashboard.discoverNews")}
              />
              {/* <LinkCard link="" icon={<img src="/images/sponsor.svg" alt="parrainage" />} text={t("dashboard.sponsorlink")} /> */}
            </Stack>
          </Stack>
        </Stack>
      )}

      {openLastActions && <LastActionsModal isInClient handleClose={() => setOpenLastActions(false)} />}
    </Stack>
  );
};

export default AffiliateDashboardComponent;
