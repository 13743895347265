import { Colors } from "@constants/colors.constant";
import { alpha, outlinedInputClasses, Stack, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TimePicker } from "@mui/x-date-pickers";
import { useState } from "react";

const StyledRedTimePicker = styled(TimePicker)({
  backgroundColor: "transparent !important",
  borderRadius: 7,
  [`& .${outlinedInputClasses.root}`]: {
    backgroundColor: alpha(Colors.primary, 0.1),
    height: 35,
  },
  [`& .${outlinedInputClasses.inputAdornedStart}`]: {
    paddingLeft: "10px",
  },
  [`& .${outlinedInputClasses.input}`]: {
    color: Colors.primary,
    fontSize: 14,
    fontWeight: 500,
    padding: "auto 8px !important",
    "&::placeholder": {
      opacity: 1,
    },
  },
}) as typeof TimePicker;

interface HourPickerComponentProps {
  color?: "primary" | "secondary";
  disabled?: boolean;
  format?: string;
  handleChange?: (date?: Date) => void;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  title?: string;
  error?: string;
  value?: Date;
}

const HourPickerComponent = (props: HourPickerComponentProps) => {
  const { color = "primary", disabled, format = "HH:mm", handleChange, placeholder, readOnly, required, title, error, value } = props;

  const [open, setOpen] = useState(false);

  return (
    <StyledRedTimePicker
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      disabled={disabled}
      readOnly={readOnly}
      value={value || null}
      ampm={false}
      inputFormat={format}
      onChange={(date) => handleChange?.(date || undefined)}
      renderInput={(params) => (
        <Stack width="50%">
          {title && (
            <Typography fontSize="12px" color={Colors.secondaryText}>
              {title} {required ? "*" : undefined}
            </Typography>
          )}
          <TextField
            {...params}
            color={color}
            disabled={disabled}
            onClick={!readOnly && !disabled ? () => setOpen(true) : undefined}
            focused={readOnly ? false : undefined}
            InputProps={{
              ...params.InputProps,
              startAdornment: <img alt="clock" src="/images/icon_clock.svg" style={{ paddingRight: "6px", height: 20, width: 20 }} />,
              endAdornment: <img alt="" style={{ transform: open ? "rotate(180deg)" : undefined }} src="/images/arrow_dropdownlist_primary.svg" />,
            }}
            inputProps={{
              ...params.inputProps,
              readOnly: true,
              placeholder: placeholder,
            }}
            error={!!error}
            helperText={error}
          />
        </Stack>
      )}
    />
  );
};

export default HourPickerComponent;
