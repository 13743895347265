import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StyledTextDescribeModal, StyledTitleModal } from "./ConfirmPublishAds.modal";

interface PublishProgrammedModalProps {
  singular: boolean;
}

const ProgrammePublishedAdModal = ({ singular }: PublishProgrammedModalProps) => {
  const { t } = useTranslation();

  return (
    <Stack alignItems="center" justifyContent="center" paddingY="1.5rem" spacing={2}>
      <img src="/images/illus_check.svg" alt="check" style={{ width: "48px", height: "48px" }} />
      {singular ? (
        <StyledTitleModal>{t("ads.details.modals.publish.programmed.ProgrammeAdPublishedTitle")}</StyledTitleModal>
      ) : (
        <StyledTitleModal>{t("ads.details.modals.publish.programmed.ProgrammeAdsPublishedTitle")}</StyledTitleModal>
      )}
      <StyledTextDescribeModal sx={{ paddingX: "1rem" }}>
        {singular
          ? t("ads.details.modals.publish.programmed.ProgrammeAdPublished")
          : t("ads.details.modals.publish.programmed.ProgrammeAdsPublished")}
      </StyledTextDescribeModal>
    </Stack>
  );
};

export default ProgrammePublishedAdModal;
