import styled from "@emotion/styled";
import { IconButton, Stack } from "@mui/material";
import { BannerModel } from "@store/banners";
import { convertFromRaw } from "draft-js";
import DOMPurify from "dompurify";

import { Options, stateToHTML } from "draft-js-export-html";
import { styleMap } from "./input/AIOTextEditor.component";
import { Colors } from "@constants/colors.constant";

const StyledBanner = styled(Stack)((props: { bgcolor: string }) => ({
  position: "relative",
  minHeight: "35px",
  padding: "8px 20px",
  backgroundColor: props.bgcolor,
  "& p": {
    margin: 0,
  },
  zIndex: 1300,
  width: "100%",
}));

const StyledCloseButton = styled(IconButton)({
  position: "absolute",
  left: "10px",
});

const StyledBannerButton = styled("a")({
  color: Colors.white,
});

interface BannerProps {
  banner: BannerModel;
  handleClose: (id: string) => void;
}

const Banner = (props: BannerProps) => {
  const { banner, handleClose } = props;

  const options: Options = { inlineStyles: {} };

  for (const style in styleMap) {
    if (options.inlineStyles) {
      options.inlineStyles[style] = { style: styleMap[style] };
    }
  }

  const contentHTML = stateToHTML(convertFromRaw(JSON.parse(banner.content)), options);

  return (
    <StyledBanner bgcolor={banner.bannerColor} direction="row" alignItems={"center"} justifyContent={"center"} spacing={"15px"}>
      <StyledCloseButton aria-label="close" onClick={() => handleClose(banner.id)}>
        <img alt="close" src="/images/icon_close_white.svg" style={{ height: "15px", width: "15px" }} />
      </StyledCloseButton>
      <Stack dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(contentHTML) }} />
      {!!(banner.buttonLabel && banner.buttonURL) && (
        <StyledBannerButton href={banner.buttonURL} target="_blank" rel="noreferrer">
          {banner.buttonLabel}
        </StyledBannerButton>
      )}
    </StyledBanner>
  );
};

export default Banner;
