import { Stack } from "@mui/material";
import { StyledTextDescribeModal, StyledTitleModal } from "@components/modal/publishAds/ConfirmPublishAds.modal";
import { useTranslation } from "react-i18next";

const CloseAdModal = () => {
  const { t } = useTranslation();

  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} mb={3} mt={2}>
      <img src="/images/icon_closeAd_primary.svg" alt="check" style={{ width: "56px", height: "47px", marginBottom: "1rem" }} />
      <StyledTitleModal>{t("multiDiffusion.tab.adStatus.toCorrectModal.closeAdModal.title")}</StyledTitleModal>
      {/* //TODO Phrase à corriger ?????? */}
      <StyledTextDescribeModal>{t("multiDiffusion.tab.adStatus.toCorrectModal.closeAdModal.description")}</StyledTextDescribeModal>
    </Stack>
  );
};

export default CloseAdModal;
