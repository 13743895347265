import { alpha, Avatar, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Colors } from "@constants/colors.constant";
import { AffiliateRoutes, Pages } from "@utils/routes.utils";

import { AnnouncementAffiliateModel } from "@store/entities/afiliates";
import { sessionQuery } from "@store/session";
import { UserRoleEnum } from "@store/users";

import AIOButtonComponent from "@components/Button.component";

interface AffiliateAdCardProps {
  affiliate: AnnouncementAffiliateModel;
}

const AffiliateAdCard = (props: AffiliateAdCardProps) => {
  const {affiliate} = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{ borderRadius: "15px" }}
      spacing={breakpointDownSM ? 1 : 0}
      bgcolor={Colors.greyCardBg}
      p="24px 18px"
      alignItems="center">
      <Stack spacing={2} direction="row" alignItems="center">
        <Avatar
          sizes="contains"
          sx={{
            border: "none",
            borderRadius: "7.5px !important",
            backgroundColor: affiliate.picture ? Colors.white : alpha(Colors.lightpurple, 0.6),
            color: Colors.secondary,
            height: 53,
            width: 53,
          }}
          src={affiliate.picture}>
          {affiliate.name[0]}
        </Avatar>
        <Typography fontSize={16} fontWeight={700} lineHeight={1.25}>
          {affiliate.name}
        </Typography>
      </Stack>
      {sessionQuery.role === UserRoleEnum.ADMIN && (
        <Stack height="min-content">
          <AIOButtonComponent
            variant="contained"
            onClick={() => navigate(`/${Pages.CLIENTS}/${Pages.AFFILIATES}/${affiliate.id}/${AffiliateRoutes.DETAILS}`)}
            title={t("ads.details.detailTab.consult")} />
        </Stack>
      )}
    </Stack>
  );
};

export default AffiliateAdCard;
