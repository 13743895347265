import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StyledTextDescribeModal, StyledTitleModal } from "./ConfirmPublishAds.modal";

const ClosedAdModal = () => {
  const { t } = useTranslation();

  return (
    <Stack alignItems="center" justifyContent="center" paddingY="1.5rem" spacing={2}>
      <img src="/images/illus_check.svg" alt="check" style={{ width: "48px", height: "48px" }} />

      <StyledTitleModal>{t("ads.details.modals.publish.confirm.closedAdTitle")}</StyledTitleModal>

      <StyledTextDescribeModal sx={{ paddingX: "1rem" }}>{t("ads.details.modals.publish.confirm.closedAd")}</StyledTextDescribeModal>
    </Stack>
  );
};

export default ClosedAdModal;
