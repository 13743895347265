import { Stack, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import NowOrLaterPublishButtonAction from "./buttonActions/NowOrLaterPublish.buttonAction";
import ModalComponent from "../Modal.component";
import { OpenedModalType } from "./PublishModalsManager";

export const StyledTextDescribeModal = styled(Typography)({
  fontWeight: 400,
  fontSize: "14px",
  opacity: 0.5,
  textAlign: "center",
});

export const StyledTitleModal = styled(Typography)({
  fontWeight: 700,
  fontSize: "20px",
});

interface ConfirmPublishModalProps {
  handleClose: () => void;
  setOpenedModalType: (open: OpenedModalType) => void;
  singular: boolean;
  onPublishNow: () => void;
  onPublishLater: () => void;
  loading: boolean;
}

const ConfirmPublishAdsModal = ({ handleClose, singular, setOpenedModalType, onPublishNow, onPublishLater, loading }: ConfirmPublishModalProps) => {
  const { t } = useTranslation();

  return (
    <ModalComponent
      handleClose={() => handleClose()}
      maxWidth="sm"
      content={
        <Stack width="100%" justifyContent="center" alignItems="center" spacing={2} marginY={"1rem"}>
          <img src="/images/illus_warning.svg" alt="check" style={{ width: "56px", height: "47px" }} />
          <StyledTitleModal>{t("ads.details.modals.publish.confirm.title", { count: singular ? 1 : 2 })}</StyledTitleModal>
          <StyledTextDescribeModal>{t("ads.details.detailTab.multiDiffusion.description")}</StyledTextDescribeModal>
          {singular ? (
            <StyledTextDescribeModal>{t("ads.details.detailTab.multiDiffusion.comfirmPublishAd")}</StyledTextDescribeModal>
          ) : (
            <StyledTextDescribeModal>{t("ads.details.detailTab.multiDiffusion.comfirmPublishAds")}</StyledTextDescribeModal>
          )}
        </Stack>
      }
      actions={
        <NowOrLaterPublishButtonAction
          displayCancelButton={true}
          onPublishNow={onPublishNow}
          onPublishLater={onPublishLater}
          onCancel={handleClose}
          loading={loading}
        />
      }
    />
  );
};

export default ConfirmPublishAdsModal;
