import AIOButtonComponent from "@components/Button.component";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

const CloseButtonAction = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();

  return (
    <Stack justifyContent={"center"}>
      <AIOButtonComponent title={t("ads.details.modals.publish.close_button")} onClick={onClose} variant="contained" />
    </Stack>
  );
};

export default CloseButtonAction;
