import CommentsComponent from "@components/input/Comments.component";
import { Stack, Typography } from "@mui/material";
import { adDetailsService } from "@store/ads/details";
import { Comment } from "@store/common/comments.model";
import { affiliateDetailsService } from "@store/entities/afiliates/details";
import useMultiDiffusionAdStore from "@store/multiDiffusionAd/multiDiffusionAd.store";
import { UserRoleEnum } from "@store/users";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MentionItem } from "react-mentions";
import { map } from "rxjs";

const AddCommentAdModal = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [comments, setComments] = useState<Comment[]>([]);

  const { manageMultiDiffusionAdSelected } = useMultiDiffusionAdStore();

  const fetchComments = useCallback(() => {
    if (!manageMultiDiffusionAdSelected) return;

    adDetailsService.getAdComments(manageMultiDiffusionAdSelected.originalAnnouncementId).subscribe({
      next: (comments) => {
        setComments(comments);
      },
    });
  }, [manageMultiDiffusionAdSelected]);

  useEffect(() => {
    fetchComments();
  }, [fetchComments]);

  const handleUpdateComment = (commentId: string, comment: string, mentions: MentionItem[]) => {
    if (!manageMultiDiffusionAdSelected) return;

    adDetailsService.updateComment(manageMultiDiffusionAdSelected.originalAnnouncementId, commentId, comment, mentions).subscribe({
      next: () => {
        fetchComments();
      },
      error: (err: any) => enqueueSnackbar(err.text, err.options),
    });
  };

  if (!manageMultiDiffusionAdSelected) return null;

  return (
    <Stack justifyContent="center" alignItems="center" spacing={2} mt={2}>
      <img src="/images/icon_bubble.svg" alt="icon_bubble" />
      <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>{t("multiDiffusion.tab.adStatus.toCorrectModal.addCommentModal.addComment")}</Typography>
      <Typography sx={{ textAlign: "center", fontSize: "14px", fontWeight: 400, opacity: 0.5 }}>
        {t("multiDiffusion.tab.adStatus.toCorrectModal.addCommentModal.addComentDescription")}
      </Typography>
      <Stack
        direction={"column"}
        spacing={2}
        width={"80%"}
        justifyContent={"flex-start"}
        alignItems={"stretch"}
        height={"100%"}
        overflow={"auto"}
        paddingX={"10px"}>
        <CommentsComponent
          handleAddComment={(comment, mentions) => {
            adDetailsService.addComment(manageMultiDiffusionAdSelected.originalAnnouncementId, comment, mentions).subscribe({
              error: (err) => enqueueSnackbar(err.text, err.options),
              next: () => {
                fetchComments();
              },
            });
          }}
          handleUpdateComment={handleUpdateComment}
          comments={comments}
          fetchTags={() =>
            affiliateDetailsService.getAffiliateUsers(manageMultiDiffusionAdSelected.affiliate).pipe(
              map((users) =>
                users
                  .filter((user) => user.role !== UserRoleEnum.ADMIN)
                  .map((user) => ({
                    id: user.email,
                    display: `${user.firstname} ${user.lastname}`,
                  }))
              )
            )
          }
        />
      </Stack>
    </Stack>
  );
};

export default AddCommentAdModal;
