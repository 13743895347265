import { Colors } from "@constants/colors.constant";
import { Stack, Typography, alpha } from "@mui/material";

interface MultiDiffusionAdsDetailsProps {
  value: number;
  color: string;
  icon: JSX.Element;
  content: string;
}

const MultiDiffusionAdsDetails = (props: MultiDiffusionAdsDetailsProps) => {
  const { value, color, icon, content } = props;
  return (
    <Stack style={{ backgroundColor: alpha(color, 0.15), padding: "20px 15px", borderRadius: "15px", flex: 1 }}>
      <Stack direction="row" alignItems="center" gap={2}>
        <Stack style={{ backgroundColor: Colors.white, borderRadius: "50%", padding: "10px", display: "flex", alignItems: "center", justifyContent: "center", width: "60px", height: "60px" }}>
          {icon}
        </Stack>
        <Stack>
          <Typography fontWeight={400} fontSize="40px" lineHeight={"40px"}>
            {value}
          </Typography>
          <Typography fontWeight={400} fontSize="14px" color={alpha(Colors.boostCard.primaryText, 0.6)}>
            {content}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MultiDiffusionAdsDetails;