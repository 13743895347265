import I18n from "@utils/i18n.utils";
import { array, date, mixed, number, object, string, ValidationError } from "yup";

export interface FieldErrors {
  [field: string]: string;
}

export enum FieldValidationType {
  REQUIRED_STRING = "REQUIRED_STRING",
  REQUIRED_NUMBER = "REQUIRED_NUMBER",
  REQUIRED_EMAIL = "REQUIRED_EMAIL",
  REQUIRED_ARRAY = "REQUIRED_ARRAY",
  REQUIRED_EMAIL_ARRAY = "REQUIRED_EMAIL_ARRAY",
  REQUIRED_SELECT_ITEM = "REQUIRED_SELECT_ITEM",
  REQUIRED_PASSWORD = "REQUIRED_PASSWORD",
  REQUIRED_FILE = "REQUIRED_FILE",
  REQUIRED_DATE = "REQUIRED_DATE",
  WEBSITE = "WEBSITE",
  REQUIRED_YOUTUBE_LINK = "REQUIRED_YOUTUBE_LINK",
  HEX_COLOR = "HEX_COLOR",
  RGB_COLOR = "RGB_COLOR",
  REQUIRED_STRIPE_PRODUCT_DATA = "REQUIRED_STRIPE_PRODUCT_DATA",
}

const fieldsValidation = {
  [FieldValidationType.REQUIRED_STRING]: string().nullable().required(I18n.t("errors.required")),
  [FieldValidationType.REQUIRED_EMAIL]: string().nullable().required(I18n.t("errors.required")).email(I18n.t("errors.email")),
  [FieldValidationType.REQUIRED_EMAIL_ARRAY]: array().nullable().required(I18n.t("errors.required")).of(string().email(I18n.t("errors.email"))),
  [FieldValidationType.WEBSITE]: string().nullable().url(I18n.t("errors.url")),
  [FieldValidationType.REQUIRED_YOUTUBE_LINK]: string()
    .nullable()
    .required(I18n.t("errors.required"))
    .test("youtubeLink", I18n.t("errors.youtubeLink"), (value) => !!value && value.includes("watch?v=") && value.includes("youtube")),
  [FieldValidationType.REQUIRED_ARRAY]: array().nullable().required(I18n.t("errors.required")).min(1, I18n.t("errors.required")),
  [FieldValidationType.REQUIRED_NUMBER]: number().nullable().required(I18n.t("errors.required")),
  [FieldValidationType.REQUIRED_SELECT_ITEM]: object().nullable().required(I18n.t("errors.required")),
  [FieldValidationType.REQUIRED_FILE]: mixed()
    .required(I18n.t("errors.importFile"))
    .test("fileSize", I18n.t("errors.fileTooLarge"), (value) => value && value.size < 10000000),
  [FieldValidationType.REQUIRED_PASSWORD]: string()
    .required(I18n.t("errors.required"))
    .min(8, I18n.t("errors.tooShort", { count: 8 }))
    .test("oneUppercase", I18n.t("errors.oneUppercase"), (value) => !!value && /[A-Z]/.test(value))
    .test("oneLowercase", I18n.t("errors.oneLowercase"), (value) => !!value && /[a-z]/.test(value)),
  [FieldValidationType.REQUIRED_DATE]: date().nullable().required(I18n.t("errors.required")),
  [FieldValidationType.HEX_COLOR]: string()
    .nullable()
    .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, I18n.t("errors.hexColor")),
  [FieldValidationType.RGB_COLOR]: string()
    .nullable()
    .matches(
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\s*,\s*(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\s*,\s*(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
      I18n.t("errors.rgbColor")
    ),
  [FieldValidationType.REQUIRED_STRIPE_PRODUCT_DATA]: mixed().nullable().required(I18n.t("errors.requiredOnStripe")),
};

export const getFieldError = (value: any, validationType: FieldValidationType) => {
  try {
    fieldsValidation[validationType].validateSync(value);
  } catch (err: any) {
    return err.message;
  }
};

export const checkIfErrors: any = (errors: Object) =>
  Object.values(errors).some((err) => (typeof err === "string" || typeof err === "undefined" ? !!err : checkIfErrors(err)));

export const transformError = (err: ValidationError): FieldErrors => {
  return err.inner.reduce((formError: any, innerError: any) => {
    const oldError = formError[innerError.path!];

    return {
      ...formError,
      [innerError.path!]: oldError || innerError.message,
    };
  }, {} as FieldErrors);
};
