import { useCallback, useEffect, useRef, useState } from "react";
import { Box, CircularProgress, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useObservable } from "@ngneat/react-rxjs";
import { useEffectFn } from "@ngneat/effects-hooks";

import { sessionQuery } from "@store/session";
import {
  CompaniesFiltersFunctions,
  companiesQuery,
  companiesService,
  CompanySortEnumFunctions,
  searchCompaniesEffect,
} from "@store/ai-o/companies";
import { UserRoleEnum } from "@store/users";

import AIOSearchComponent from "@components/input/AIOSearch.component";
import AIOSelectComponent from "@components/input/Select.component";
import CompanyAioCard from "@components/card/CompanyAio.card";
import { emitOnce } from "@ngneat/elf";
import { InfiniteScrollContainer, VirtualScrollItem } from "@utils/infinitescroll.utils";
import BackToTopButton from "@components/BackToTopButton.component";
import { AdminAioClientRoutes } from "@utils/routes.utils";
import { useScroll } from "ahooks";
import { create } from "zustand";
import { useFirstRender } from "@utils/hooks/useFirstRender";

interface PageState {
  scrollPosition: number;
  setPosition: (x: number) => void;
}

const pageStore = create<PageState>((set) => ({
  scrollPosition: 0,
  setPosition: (position: number) => set({ scrollPosition: position }),
}));

const AioCustomersTab = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { scrollPosition, setPosition } = pageStore();
  const firstRender = useFirstRender();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const scrollableCompaniesRef = useRef<HTMLDivElement | null>(null);
  const scrollableCompaniesMobileRef = useRef<HTMLDivElement | null>(null);
  const scroll = useScroll(scrollableCompaniesRef);

  const searchCompanies = useEffectFn(searchCompaniesEffect);

  const [filters] = useObservable(companiesQuery.filters$);

  const [sort] = useObservable(companiesQuery.sort$);

  const [{ companies, error: companiesError, loading }] = useObservable(companiesQuery.companies$);
  const [companiesPaginatedData] = useObservable(companiesQuery.companiesPaginationData$);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 24;

  useEffect(() => {
    if (companiesError) enqueueSnackbar((companiesError as any).text, (companiesError as any).options);
  }, [companiesError, enqueueSnackbar]);

  useEffect(() => {
    searchCompanies({ filters, sort, page: currentPage, take: itemsPerPage });
  }, [filters, searchCompanies, sort, itemsPerPage, currentPage]);

  useEffect(() => {
    if (!firstRender) {
      emitOnce(() => {
        companiesService.deleteCompanies();
        companiesService.deleteAllPages();
      });
      setCurrentPage(1);
    }
  }, [filters, firstRender, sort]);

  const handleNextPage = useCallback(() => {
    if (companiesPaginatedData.lastPage >= companiesPaginatedData.currentPage) {
      setCurrentPage(companiesPaginatedData.currentPage + 1);
    }
  }, [companiesPaginatedData.currentPage, companiesPaginatedData.lastPage]);

  const backToTop = () => {
    if (scrollableCompaniesRef.current)
      scrollableCompaniesRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    if (scrollableCompaniesMobileRef.current)
      scrollableCompaniesMobileRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  };

  useEffect(() => {
    if (scroll) setPosition(scroll.top);
  }, [scroll]);

  useEffect(() => {
    if (scrollableCompaniesRef.current)
      scrollableCompaniesRef.current.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    if (scrollableCompaniesMobileRef.current)
      scrollableCompaniesMobileRef.current.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
  }, []);

  return (
    <Stack
      pt={breakpointDownSM ? "10px" : sessionQuery.role === UserRoleEnum.ADMIN ? "20px" : "0px"}
      spacing={breakpointDownSM ? 0 : 4}
      flex={1}
      width="100%"
      overflow="auto">
      <Stack
        ref={scrollableCompaniesMobileRef}
        overflow="auto"
        className={breakpointDownSM ? "scrollable" : undefined}
        spacing={breakpointDownSM ? 2 : 0}
        py={breakpointDownSM ? "20px" : undefined}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack
            direction={breakpointDownSM ? "column" : "row"}
            alignItems="center"
            justifyContent={breakpointDownSM ? "flex-start" : "flex-end"}
            spacing={breakpointDownSM ? 0 : 3}
            width="100%">
            <AIOSearchComponent
              placeholder={t("aio.clients.searchPlaceholder")}
              value={filters.search}
              fullWidth
              onChange={(value) => companiesService.setFilters({ search: value })}
            />
            <Stack direction="row" justifyContent={breakpointDownSM ? "center" : "flex-end"} flexWrap="wrap">
              <AIOSelectComponent
                placeholder={t("aio.clients.filterByState")}
                items={CompaniesFiltersFunctions.stateSelectItems}
                handleChange={(value) =>
                  companiesService.setFilters({ reattach: value === "" ? undefined : value, banned: value === "" ? undefined : value })
                }
                value={filters.reattach}
              />
              <AIOSelectComponent
                items={CompanySortEnumFunctions.selectItems}
                handleChange={(value) => companiesService.setSort({ field: value })}
                value={sort.field}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack
          spacing={2}
          overflow={breakpointDownSM ? undefined : "auto"}
          className={breakpointDownSM ? undefined : "scrollable"}
          py={breakpointDownSM ? "0px" : "30px"}
          px="10px"
          ref={scrollableCompaniesRef}>
          <Typography fontSize="16px" fontWeight="700">
            {loading ? "-" : ""}
            {!loading && t(`aio.clients.client`, { count: companiesPaginatedData.total ?? 0 })}
          </Typography>
          <Box>
            <Grid container spacing={3}>
              <InfiniteScrollContainer
                nextPageHandler={handleNextPage}
                itemsPerPage={itemsPerPage}
                componentType="Grid"
                listItems={companies.map((company) => (
                  <VirtualScrollItem
                    key={company.id}
                    height={130}
                    children={<CompanyAioCard company={company} handleClick={() => navigate(`${company.id}/${AdminAioClientRoutes.DETAILS}`)} />}
                  />
                ))}
              />
            </Grid>
          </Box>
          <Stack direction="row" width="100%" justifyContent="center" mt="20px" height={"80px"} alignItems="center">
            {loading && <CircularProgress size={30} />}
          </Stack>
          <BackToTopButton onClick={backToTop} posRight={breakpointDownSM ? "25px" : "60px"} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AioCustomersTab;
