import React, { useEffect, useState } from "react";
import { Box, CardActionArea, Grid, IconButton, Pagination, PaginationItem, Stack, styled, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DataGrid, GridColDef, GridFooterContainer } from "@mui/x-data-grid";
import { Colors } from "@constants/colors.constant";
import AIOButtonComponent from "@components/Button.component";
import AIOSearchComponent from "@components/input/AIOSearch.component";
import AIOSelectComponent from "@components/input/Select.component";
import theme from "../../../../themes";
import { finalize, forkJoin } from "rxjs";
import ClientsNotReattachedModal from "@screens/auth/admin/aio/components/ClientsNotReattached.modal";
import { useEffectFn } from "@ngneat/effects-hooks";
import {
  Candidate,
  CandidatesFiltersFunctions,
  CandidateSortEnumFunctions,
  candidatesQuery,
  candidatesService,
  searchCandidatesEffect,
} from "@store/ai-o/candidates";
import { useObservable } from "@ngneat/react-rxjs";
import { emitOnce } from "@ngneat/elf";
import { useSnackbar } from "notistack";
import ReattachAffiliateModal from "@screens/auth/admin/aio/components/ReattachAffiliate.modal";
import AssociateAnnouncementModal from "@screens/auth/admin/aio/components/AssociateAnnouncement.modal";
import { filesService } from "@store/files";
import { companiesQuery, companiesService } from "@store/ai-o/companies";
import { adDetailsService } from "@store/ads/details";
import EastIcon from "@mui/icons-material/East";

const StyledDataGrid = styled(DataGrid)({
  border: "none !important",
  "& .MuiDataGrid-virtualScroller": {
    overflowX: "hidden",
  },
  "& .MuiDataGrid-columnHeaders": {
    border: "1px solid rgba(34, 34, 64, 0.05)",
    backgroundColor: Colors.white,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 300,
  },
  "& .MuiDataGrid-withBorder": {
    borderRight: "none !important",
  },
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-row": {
    minHeight: "61px !important",
    backgroundColor: Colors.greyCardBg,
  },
  "& .MuiDataGrid-footerContainer": {
    border: "none",
    justifyContent: "flex-end",
  },
  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
    outline: "none !important",
  },
  "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus": {
    outline: "none !important",
  },
  "& .headerCell": {
    display: "flex",
    alignItems: "center",
    padding: "8px 16px",
    color: Colors.black,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },
  "& .bodyCell": {
    display: "flex",
    alignItems: "center",
    padding: "8px 16px",
    color: Colors.black,
    lineHeight: 1.25,
    fontWeight: 300,
  },
  "& .bodyCell.bold": {
    fontWeight: 500,
  },
  "& .MuiPaginationItem-root:not([disabled]) svg": {
    fill: Colors.primary,
  },
  "& .MuiDataGrid-cell": {
    minHeight: "61px !important",
    fontWeight: 300,
    whiteSpace: "pre-wrap !important",
    "& .MuiButton-root": {
      marginLeft: "-12px",
      padding: "10px 15px !important",
    },
  },
  "& .MuiDataGrid-cell > div": {
    maxWidth: "100%",
  },
});

export const AIOButtonComponentOnTable = styled(AIOButtonComponent)({
  marginLeft: "-10px",
  padding: "10px !important",
});

export const SmallChip = styled(Stack)((props: { isAttached?: boolean }) => ({
  color: Colors.white,
  backgroundColor: props.isAttached ? Colors.affiliate : Colors.greyLight,
  padding: "0 6px",
  borderRadius: "5px",
}));

const AioApplicationsTab = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const searchCandidates = useEffectFn(searchCandidatesEffect);

  const [filters] = useObservable(candidatesQuery.filters$);

  const [sort] = useObservable(candidatesQuery.sort$);

  const [{ candidates, error, loading }] = useObservable(candidatesQuery.candidates$);
  const [candidatesPaginationData] = useObservable(candidatesQuery.candidatesPaginationData$);

  const [notReattachedCount] = useObservable(companiesQuery.notReattachedWithApplicationCount$);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;

  const [isNotReattachedOpen, setIsNotReattachedOpen] = useState(false);
  const [openReattachAffiliate, setOpenReattachAffiliate] = useState<Candidate | false>(false);
  const [openAssociateAd, setOpenAssociateAd] = useState<Candidate | false>(false);

  const [loadingAssociateAuto, setLoadingAssociateAuto] = useState(false);

  useEffect(() => candidatesService.resetStore(), []);

  useEffect(() => {
    if (error) enqueueSnackbar((error as any).text, (error as any).options);
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    companiesService.getNotReattachedCompaniesWithApplicationsCount().subscribe({
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  }, [enqueueSnackbar]);

  useEffect(() => {
    searchCandidates({ filters, sort, page: currentPage, take: itemsPerPage });
  }, [filters, searchCandidates, sort, itemsPerPage, currentPage]);

  useEffect(() => {
    emitOnce(() => {
      candidatesService.deleteCandidates();
      candidatesService.deleteAllPages();
    });
    setCurrentPage(1);
  }, [filters, sort]);

  const associateCandidateAutomatically = async () => {
    setLoadingAssociateAuto(true);

    adDetailsService
      .associateAutomatically()
      .pipe(finalize(() => setLoadingAssociateAuto(false)))
      .subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options),
        next: (associatedNb) => {
          if (associatedNb > 0) {
            enqueueSnackbar(t("aio.applications.success.associateAutomatically", { count: associatedNb }), { variant: "success" });
            candidatesService.getCandidates(filters, sort, currentPage, itemsPerPage).subscribe();
          } else {
            enqueueSnackbar(t("aio.applications.success.noApplicationToAssociate"), { variant: "warning" });
          }
        },
      });
  };

  const columns: GridColDef[] = [
    {
      field: "jobName",
      headerName: t("aio.applications.table.jobTitle"),
      flex: 1,
      minWidth: 150,
      cellClassName: "bodyCell bold",
      sortable: false,
      renderCell: (params) => {
        const ref = params.row.job.reference
          ? `REF(Flux) : ${params.row.job.reference}`
          : params.row.job.fromPEAPI
          ? `REF(PE) : ${params.row.job.externalId}`
          : null;
        return `${params.row.job.name}${ref ? `\n${ref}` : ""}`;
      },
    },
    {
      field: "candidateInfo",
      headerName: t("aio.applications.table.candidate"),
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: (params) => (params.row.candidateInfo ? `${params.row.candidateInfo.firstName} ${params.row.candidateInfo.lastName}` : ""),
    },
    {
      field: "companyName",
      headerName: t("aio.applications.table.company"),
      flex: 1,
      minWidth: 100,
      sortable: false,
    },
    {
      field: "companyAffiliateId",
      headerName: "ALLinOne",
      width: 110,
      sortable: false,
      valueGetter: (params) => !!params.row.companyAffiliateId,
      renderCell: (params) => (
        <SmallChip isAttached={params.value} direction="row" alignItems="center" spacing={0.5}>
          {params.value && <img alt="logo aio" src="/images/logo_allinone_small.svg" />}
          <Typography fontSize="12px">{params.value ? t("aio.clients.reattached") : t("aio.clients.notReattached")}</Typography>
        </SmallChip>
      ),
    },
    {
      field: "companyAffiliateName",
      headerName: t("aio.applications.table.attachedClient"),
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: (params) =>
        params.row.companyAffiliateId ? null : (
          <AIOButtonComponentOnTable
            title={t("aio.applications.table.reattach")}
            color="primary"
            icon={
              <Stack height="10px">
                <img height="100%" alt="reattach" src="/images/icon_doublearrow_red.svg" />
              </Stack>
            }
            onClick={() => setOpenReattachAffiliate(params.row)}
          />
        ),
    },
    {
      field: "creationDate",
      headerName: t("aio.applications.table.date"),
      width: 100,
      sortable: false,
      valueFormatter: (params) => (params.value ? t("global.date", { date: new Date(params.value) }) : ""),
    },
    {
      field: "files",
      headerName: t("aio.applications.table.application"),
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <IconButton disabled={!params.row.coverLetterURL} onClick={() => filesService.openUrlInNewTab(params.row.coverLetterURL)}>
            {params.row.coverLetterURL ? (
              <img alt="icon cover letter red" src="/images/file_cover_letter_red.svg" />
            ) : (
              <img alt="icon cover letter grey" src="/images/file_cover_letter_grey.svg" />
            )}
          </IconButton>
          <IconButton disabled={!params.row.cvURL} onClick={() => filesService.openUrlInNewTab(params.row.cvURL)}>
            {params.row.cvURL ? <img alt="icon cv red" src="/images/file_cv_red.svg" /> : <img alt="icon cv grey" src="/images/file_cv_grey.svg" />}
          </IconButton>
        </Stack>
      ),
    },
    {
      field: "announcementId",
      headerName: t("aio.applications.table.relatedAd"),
      minWidth: 150,
      sortable: false,
      renderCell: (params) => {
        if (!params.row.companyAffiliateId) {
          return "-";
        } else if (!params.row.announcementId) {
          return (
            <AIOButtonComponentOnTable
              color="primary"
              title={t("aio.applications.table.associate")}
              icon={<img height="100%" alt="associate" src="/images/associate.svg" />}
              onClick={() => setOpenAssociateAd(params.row)}
            />
          );
        }
        return params.row.announcementRef ? t("global.reference", { reference: params.row.announcementRef }) : "-";
      },
    },
  ];

  const CustomFooter = () => {
    return (
      <GridFooterContainer>
        <Pagination
          color="primary"
          count={candidatesPaginationData.lastPage}
          page={currentPage}
          onChange={(event, newPage) => {
            if (candidatesPaginationData.lastPage >= newPage) setCurrentPage(newPage);
          }}
          renderItem={(item) => {
            if (item.type === "page" && item.selected)
              return (
                <Stack direction="row" alignItems="row" justifyContent="center">
                  <Typography color="primary" fontWeight={500}>
                    {item.page}
                  </Typography>
                  <Typography fontWeight={300}>/{candidatesPaginationData.lastPage}</Typography>
                </Stack>
              );
            if (item.type !== "page" && item.type !== "end-ellipsis" && item.type !== "start-ellipsis") return <PaginationItem {...item} />;
          }}
        />
      </GridFooterContainer>
    );
  };

  return (
    <Stack width="100%" spacing={3} flex={1} overflow="auto">
      <Typography fontSize="20px" fontWeight="700">
        {t("aio.applications.title")}
      </Typography>

      <Grid container>
        <Grid item>
          <Stack
            width={"320px"}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            component={CardActionArea}
            onClick={() => setIsNotReattachedOpen(true)}
            bgcolor={Colors.lightPink}
            padding={"10px 20px"}
            borderRadius={"20px"}>
            <Typography>{`${notReattachedCount} ${t("aio.applications.companiesNotReattached")}`}</Typography>
            <EastIcon color={"primary"} />
          </Stack>
        </Grid>
      </Grid>

      <Stack
        direction={breakpointDownSM ? "column" : "row"}
        alignItems="center"
        justifyContent={breakpointDownSM ? "flex-start" : "space-between"}
        spacing={breakpointDownSM ? 0 : 6}>
        <AIOSearchComponent
          fullWidth
          placeholder={t("aio.applications.searchPlaceholder")}
          onChange={(value) => candidatesService.setFilters({ search: value })}
        />
        <Stack direction="row" justifyContent={breakpointDownSM ? "center" : "flex-end"} flexWrap={breakpointDownSM ? "wrap" : "nowrap"}>
          <AIOSelectComponent
            placeholder={t("aio.applications.filterNotAttachedCompany")}
            items={CandidatesFiltersFunctions.companyStateSelectItems}
            handleChange={(value) => candidatesService.setFilters({ notAttachedCompany: value === "" ? undefined : value })}
            value={filters.notAttachedCompany}
          />
          <AIOSelectComponent
            placeholder={t("aio.applications.filterNotAttachedAnnouncement")}
            items={CandidatesFiltersFunctions.announcementStateSelectItems}
            handleChange={(value) => candidatesService.setFilters({ notAttachedAnnouncement: value === "" ? undefined : value })}
            value={filters.notAttachedAnnouncement}
          />
          <AIOSelectComponent
            items={CandidateSortEnumFunctions.selectItems}
            handleChange={(value) => candidatesService.setSort({ field: value })}
            value={sort.field}
          />
          <Box ml={"10px"}>
            <AIOButtonComponent
              variant="contained"
              color="secondary"
              disabled={loadingAssociateAuto}
              onClick={associateCandidateAutomatically}
              title={t("aio.applications.associateAutomatically")}
            />
          </Box>
        </Stack>
      </Stack>

      <Stack direction="row" width="100%" height="100%" justifyContent="center" alignItems="center">
        <Stack sx={{ height: "100%", width: "100%" }}>
          <StyledDataGrid
            rows={candidates}
            loading={loading}
            rowsPerPageOptions={[]}
            pagination
            disableColumnMenu
            disableSelectionOnClick
            paginationMode="server"
            filterMode="server"
            rowCount={candidatesPaginationData.total}
            page={currentPage - 1}
            pageSize={candidatesPaginationData.perPage}
            headerHeight={60}
            columns={columns}
            components={{
              Footer: CustomFooter,
            }}
          />
        </Stack>
      </Stack>

      {isNotReattachedOpen && <ClientsNotReattachedModal handleClose={() => setIsNotReattachedOpen(false)} />}
      {!!openReattachAffiliate && (
        <ReattachAffiliateModal
          candidateCompanyInfo={{
            companyId: openReattachAffiliate.companyId,
            companyName: openReattachAffiliate.companyName,
            companyDescription: openReattachAffiliate.companyDescription,
            companyLogoURL: openReattachAffiliate.companyLogoURL,
            companyLocation: openReattachAffiliate.companyLocation,
          }}
          handleSuccess={() =>
            forkJoin([
              candidatesService.getCandidates(filters, sort, currentPage, itemsPerPage),
              companiesService.getNotReattachedCompaniesWithApplicationsCount(),
            ])
          }
          handleClose={() => setOpenReattachAffiliate(false)}
        />
      )}
      {!!openAssociateAd && (
        <AssociateAnnouncementModal
          candidate={openAssociateAd}
          updateCandidates={() => candidatesService.getCandidates(filters, sort, currentPage, itemsPerPage)}
          handleClose={() => setOpenAssociateAd(false)}
        />
      )}
    </Stack>
  );
};

export default AioApplicationsTab;
