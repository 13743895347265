import { useMemo, useState } from "react";
import ModalComponent from "../Modal.component";
import { useTranslation } from "react-i18next";
import PublishLaterModal from "./LaterPublish.modal";
import PublishButtonAction from "./buttonActions/Publish.buttonAction";
import CloseButtonAction from "./buttonActions/Close.buttonAction";
import PublishedModal from "./Published.modal";
import useMultiDiffusionAdStore, { MultiDiffusionModalEnum } from "@store/multiDiffusionAd/multiDiffusionAd.store";
import AIOButtonComponent from "@components/Button.component";
import CorrectAdModal from "../updatePublishAds/CorrectAds.modal";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import AddCommentAdModal from "../updatePublishAds/AddCommentAd.modal";
import RefreshAdModal from "../updatePublishAds/RefreshAd.modal";
import CloseAdModal from "../updatePublishAds/CloseAd.modal";
import ProgrammePublishedAdModal from "./ProgrammePublishedAd.modal";
import styled from "@emotion/styled";
import { adsService, MultiDiffusionAdStatusEnum } from "@store/ads";
import { useSnackbar } from "notistack";
import { finalize } from "rxjs";
import EditedAdModal from "./EditedAd.modal";
import ClosedAdModal from "./ClosedAd.modal";
import RefreshedAdModal from "./RefreshedAd.modal";

export const StyledTextDescribeModal = styled(Typography)({
  fontWeight: 400,
  fontSize: "14px",
  opacity: 0.5,
  textAlign: "center",
});

export const StyledTitleModal = styled(Typography)({
  fontWeight: 700,
  fontSize: "20px",
});

export type OpenedModalType = "filters" | "adSelection" | "confirmPublish" | "publishedNow" | "publishedLater" | "publishLater" | "published" | null;

const MultiDiffusionModalsManager = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<Date | null>(null);
  const [loadingEditProgrammedAd, setLoadingEditProgrammedAd] = useState(false);
  const [loadingRefreshAd, setLoadingRefreshAd] = useState(false);
  const [loadingCloseAd, setLoadingCloseAd] = useState(false);

  const { modalTypeOpened, setModalTypeOpened, manageMultiDiffusionAdSelected, setLoadingPublish, loadingPublish } = useMultiDiffusionAdStore();

  const isProgrammedAd = useMemo(
    () => manageMultiDiffusionAdSelected?.status === MultiDiffusionAdStatusEnum.PROGRAMMED,
    [manageMultiDiffusionAdSelected]
  );

  const onPublishNow = () => {
    if (!manageMultiDiffusionAdSelected) return;

    setLoadingPublish(true);

    adsService
      .publishCorrectedMultiDiffusionAd(manageMultiDiffusionAdSelected)
      .pipe(finalize(() => setLoadingPublish(false)))
      .subscribe({
        next: () => {
          setModalTypeOpened(MultiDiffusionModalEnum.PUBLISHED_NOW);
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const onEditProgrammedAd = () => {
    if (!manageMultiDiffusionAdSelected) return;
    setLoadingEditProgrammedAd(true);

    adsService
      .editMultiDiffusionAd(manageMultiDiffusionAdSelected)
      .pipe(finalize(() => setLoadingEditProgrammedAd(false)))
      .subscribe({
        next: () => {
          setModalTypeOpened(MultiDiffusionModalEnum.EDITED_AD);
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const handleRefreshAd = () => {
    if (!manageMultiDiffusionAdSelected) return;
    setLoadingRefreshAd(true);

    adsService
      .refreshMultiDiffusionAd(manageMultiDiffusionAdSelected)
      .pipe(finalize(() => setLoadingRefreshAd(false)))
      .subscribe({
        next: (jtmoErrors) => {
          if (!jtmoErrors.length) return setModalTypeOpened(MultiDiffusionModalEnum.REFRESHED_AD);
          enqueueSnackbar(jtmoErrors[0].libelleMotif, { variant: "error" });
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const handleCloseAd = () => {
    if (!manageMultiDiffusionAdSelected) return;
    setLoadingCloseAd(true);

    adsService
      .closeMultiDiffusionAd(manageMultiDiffusionAdSelected)
      .pipe(finalize(() => setLoadingCloseAd(false)))
      .subscribe({
        next: (jtmoError) => {
          if (!jtmoError) return setModalTypeOpened(MultiDiffusionModalEnum.CLOSED_AD);
          enqueueSnackbar(jtmoError.libelleMotif, { variant: "error" });
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  return (
    <>
      {(modalTypeOpened === MultiDiffusionModalEnum.CORRECT_AD ||
        modalTypeOpened === MultiDiffusionModalEnum.COMMENT_AD ||
        modalTypeOpened === MultiDiffusionModalEnum.PUBLISH_AD) && (
        <>
          {modalTypeOpened === MultiDiffusionModalEnum.COMMENT_AD && (
            <ModalComponent
              handleClose={() => setModalTypeOpened(MultiDiffusionModalEnum.CORRECT_AD)}
              maxWidth="sm"
              content={<AddCommentAdModal />}
            />
          )}
          {modalTypeOpened === MultiDiffusionModalEnum.PUBLISH_AD && (
            <ModalComponent
              handleClose={() => setModalTypeOpened(null)}
              maxWidth="sm"
              content={
                !!isProgrammedAd ? (
                  <Stack width="100%" justifyContent="center" alignItems="center" spacing={2} marginY={"1rem"}>
                    <StyledTitleModal>{t("ads.details.detailTab.multiDiffusion.editAdTitle")}</StyledTitleModal>
                    <StyledTextDescribeModal>{t("ads.details.detailTab.multiDiffusion.comfirmEditAd")}</StyledTextDescribeModal>
                  </Stack>
                ) : (
                  <Stack width="100%" justifyContent="center" alignItems="center" spacing={2} marginY={"1rem"}>
                    <img src="/images/illus_warning.svg" alt="check" style={{ width: "56px", height: "47px" }} />
                    <StyledTitleModal>{t("ads.details.modals.publish.confirm.title", { count: 1 })}</StyledTitleModal>
                    <StyledTextDescribeModal>{t("ads.details.detailTab.multiDiffusion.description")}</StyledTextDescribeModal>
                    <StyledTextDescribeModal>{t("ads.details.detailTab.multiDiffusion.comfirmPublishAd")}</StyledTextDescribeModal>
                  </Stack>
                )
              }
              actions={
                <Stack direction={breakpointDownSM ? "column" : "row"} spacing={2} justifyContent="space-between">
                  {isProgrammedAd ? (
                    <AIOButtonComponent
                      variant="contained"
                      disabled={loadingEditProgrammedAd}
                      onClick={onEditProgrammedAd}
                      title={t("ads.details.modals.publish.publish")}
                    />
                  ) : (
                    <AIOButtonComponent
                      variant="contained"
                      disabled={loadingPublish}
                      onClick={onPublishNow}
                      title={t("ads.details.modals.publish.publish")}
                    />
                  )}
                  <AIOButtonComponent
                    ariaLabel="light"
                    onClick={() => setModalTypeOpened(MultiDiffusionModalEnum.CORRECT_AD)}
                    title={t("ads.details.modals.publish.confirm.cancel")}
                  />
                </Stack>
              }
            />
          )}
          <ModalComponent
            handleClose={() => setModalTypeOpened(null)}
            title={t("multiDiffusion.tab.adStatus.toCorrectModal.title")}
            titleLeft={true}
            maxWidth="md"
            content={<CorrectAdModal />}
            actions={
              manageMultiDiffusionAdSelected?.status !== MultiDiffusionAdStatusEnum.PUBLISHED ? (
                <Stack direction="row" gap={2}>
                  <AIOButtonComponent
                    ariaLabel="light"
                    onClick={() => {
                      setModalTypeOpened(null);
                      setModalTypeOpened(MultiDiffusionModalEnum.COMMENT_AD);
                    }}
                    title={t("multiDiffusion.tab.adStatus.toCorrectModal.addComment")}
                  />
                  <AIOButtonComponent
                    variant="contained"
                    title={t("multiDiffusion.tab.adStatus.toCorrectModal.validate")}
                    onClick={() => {
                      setModalTypeOpened(MultiDiffusionModalEnum.PUBLISH_AD);
                    }}
                  />
                </Stack>
              ) : undefined
            }
          />
        </>
      )}

      {modalTypeOpened === MultiDiffusionModalEnum.REFRESH_AD && (
        <ModalComponent
          handleClose={() => setModalTypeOpened(null)}
          maxWidth="sm"
          content={<RefreshAdModal />}
          actions={
            <Stack direction="row" spacing={2} justifyContent="center">
              <AIOButtonComponent
                disabled={loadingRefreshAd}
                variant="contained"
                onClick={handleRefreshAd}
                title={t("multiDiffusion.tab.adStatus.toCorrectModal.refreshAdModal.title")}
              />
              <AIOButtonComponent ariaLabel="light" onClick={() => setModalTypeOpened(null)} title={t("global.cancel")} />
            </Stack>
          }
        />
      )}
      {modalTypeOpened === MultiDiffusionModalEnum.CLOSE_AD && (
        <ModalComponent
          handleClose={() => setModalTypeOpened(null)}
          maxWidth="sm"
          content={<CloseAdModal />}
          actions={
            <Stack direction="row" spacing={2} justifyContent="center">
              <AIOButtonComponent
                disabled={loadingCloseAd}
                variant="contained"
                onClick={handleCloseAd}
                title={t("multiDiffusion.tab.adStatus.toCorrectModal.closeAdModal.title")}
              />
              <AIOButtonComponent ariaLabel="light" onClick={() => setModalTypeOpened(null)} title={t("global.cancel")} />
            </Stack>
          }
        />
      )}
      {modalTypeOpened === MultiDiffusionModalEnum.PUBLISHED_NOW && (
        <ModalComponent
          handleClose={() => setModalTypeOpened(null)}
          maxWidth="sm"
          content={<PublishedModal singular={true} />}
          actions={<CloseButtonAction onClose={() => setModalTypeOpened(null)} />}
        />
      )}
      {modalTypeOpened === MultiDiffusionModalEnum.EDITED_AD && (
        <ModalComponent
          handleClose={() => setModalTypeOpened(null)}
          maxWidth="sm"
          content={<EditedAdModal />}
          actions={<CloseButtonAction onClose={() => setModalTypeOpened(null)} />}
        />
      )}
      {modalTypeOpened === MultiDiffusionModalEnum.REFRESHED_AD && (
        <ModalComponent
          handleClose={() => setModalTypeOpened(null)}
          maxWidth="sm"
          content={<RefreshedAdModal />}
          actions={<CloseButtonAction onClose={() => setModalTypeOpened(null)} />}
        />
      )}
      {modalTypeOpened === MultiDiffusionModalEnum.CLOSED_AD && (
        <ModalComponent
          handleClose={() => setModalTypeOpened(null)}
          maxWidth="sm"
          content={<ClosedAdModal />}
          actions={<CloseButtonAction onClose={() => setModalTypeOpened(null)} />}
        />
      )}
      {modalTypeOpened === MultiDiffusionModalEnum.PUBLISHED_LATER && (
        <ModalComponent
          handleClose={() => setModalTypeOpened(null)}
          maxWidth="sm"
          content={<PublishLaterModal singular={true} onDateChange={setSelectedDate} onTimeChange={setSelectedTime} />}
          actions={
            <PublishButtonAction
              action={() => {
                setModalTypeOpened(null);
                setModalTypeOpened(MultiDiffusionModalEnum.PROGRAMME_PUBLISHED_LATER);
              }}
              selectedDate={selectedDate}
              selectedTime={selectedTime}
              setOpenedModalType={() => {}} //TODO
              loading={loadingPublish} //TODO
            />
          }
        />
      )}
      {modalTypeOpened === MultiDiffusionModalEnum.PROGRAMME_PUBLISHED_LATER && (
        <ModalComponent
          handleClose={() => setModalTypeOpened(null)}
          maxWidth="sm"
          content={<ProgrammePublishedAdModal singular={true} />}
          actions={<CloseButtonAction onClose={() => setModalTypeOpened(null)} />}
        />
      )}
    </>
  );
};

export default MultiDiffusionModalsManager;
