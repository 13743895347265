import { Box, Grid, IconButton, Stack, styled, Typography } from "@mui/material";
import React, { useCallback, useMemo, useRef, useState } from "react";

import { Colors } from "@constants/colors.constant";
import { adsQuery, adsService } from "@store/ads";
import { useObservable } from "@ngneat/react-rxjs";
import { InfiniteScrollContainer, VirtualScrollItem } from "@utils/infinitescroll.utils";

const StyledTypography = styled(Typography)({
  fontWeight: 400,
  fontSize: "14px",
  color: Colors.grey,
});

const StyledDeleteAddButtonContainer = styled(Box)({
  position: "absolute",
  top: "10px",
  right: "10px",
});

const StyledAdCard = styled(Stack)({
  boxShadow: "0px 0px 20px 0px #0000000D",
  borderRadius: "15px",
  padding: "1rem",
  position: "relative",
  height: "155px",
});

interface AnnouncementSelectedModalContentProps {
  handleClose: () => void;
}

const ITEMS_PER_PAGE = 20;

const SelectedAdsModal: React.FC<AnnouncementSelectedModalContentProps> = ({ handleClose }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const [adsToPublishFilters] = useObservable(adsQuery.adsToPublishFilters$);
  const [adsToPublish] = useObservable(adsQuery.adsToPublish$);

  const scrollableAdsRef = useRef<HTMLDivElement>(null);

  const totalPages = useMemo(() => Math.ceil(adsToPublish.length / ITEMS_PER_PAGE), [adsToPublish]);

  const handleNextPage = useCallback(() => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  }, [currentPage, totalPages]);

  const handleExcludeAd = useCallback(
    (adId: string) => {
      adsService.updateAdsToPublishFilters({ excludedAdIds: [...adsToPublishFilters.excludedAdIds, adId] });
    },
    [adsToPublishFilters.excludedAdIds]
  );

  const handleIncludeAd = useCallback(
    (adId: string) => {
      adsService.updateAdsToPublishFilters({ excludedAdIds: adsToPublishFilters.excludedAdIds.filter((id) => id !== adId) });
    },
    [adsToPublishFilters.excludedAdIds]
  );

  const adsToPublishPaginated = useMemo(() => {
    return adsToPublish.slice(0, (currentPage + 1) * ITEMS_PER_PAGE);
  }, [adsToPublish, currentPage]);

  return (
    <Box overflow="auto" className="scrollable" py="20px" px="5px" ref={scrollableAdsRef}>
      <Grid container spacing={3}>
        <InfiniteScrollContainer
          nextPageHandler={handleNextPage}
          itemsPerPage={ITEMS_PER_PAGE}
          componentType="Grid"
          listItems={adsToPublishPaginated.map((ad) => {
            const isAdExcluded = adsToPublishFilters.excludedAdIds.includes(ad.id);

            return (
              <VirtualScrollItem
                key={ad.id}
                height={155}
                children={
                  <StyledAdCard>
                    <StyledDeleteAddButtonContainer>
                      <IconButton onClick={() => (!isAdExcluded ? handleExcludeAd(ad.id) : handleIncludeAd(ad.id))}>
                        {!isAdExcluded ? (
                          <img src="/images/icon_remove.svg" alt="delete" style={{ width: "13px", height: "13px" }} />
                        ) : (
                          <img src="/images/icon_add.svg" alt="add" style={{ width: "13px", height: "13px" }} />
                        )}
                      </IconButton>
                    </StyledDeleteAddButtonContainer>
                    <Box sx={{ opacity: isAdExcluded ? 0.5 : 1, pointerEvents: isAdExcluded ? "none" : "auto" }}>
                      <StyledTypography>REF {ad.reference}</StyledTypography>
                      <StyledTypography>{ad.name}</StyledTypography>
                      <StyledTypography>{ad.affiliate.name}</StyledTypography>
                      <Stack direction="row" justifyContent="start" alignItems="center" spacing={1}>
                        <img src="/images/icon_location.svg" alt="location" style={{ width: "10px", height: "12px" }} />
                        <StyledTypography>{ad.location}</StyledTypography>
                      </Stack>
                      <Stack direction="row" justifyContent="start" alignItems="center" spacing={1}>
                        <img src="/images/icon_contract_type.svg" alt="contractType" style={{ width: "10px", height: "12px" }} />
                        <StyledTypography>{ad.contractType}</StyledTypography>
                      </Stack>
                    </Box>
                  </StyledAdCard>
                }
              />
            );
          })}
        />
      </Grid>
    </Box>
  );
};

export default SelectedAdsModal;
