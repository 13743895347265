import MultiSelectWithSearchComponent from "@components/input/MultiSelectWithSearch.component";
import { SelectItem } from "@components/input/Select.component";
import { Colors } from "@constants/colors.constant";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { useObservable } from "@ngneat/react-rxjs";
import { adsQuery } from "@store/ads";
import { AdModel, AdsToPublishFilters } from "@store/ads/ads.model";
import { adsService } from "@store/ads/ads.service";
import { affiliatesService } from "@store/entities/afiliates/affiliates.service";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalComponent from "../Modal.component";
import AIOButtonComponent from "@components/Button.component";
import { useSnackbar } from "notistack";
import { OpenedModalType } from "./PublishModalsManager";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import { debounce } from "lodash";
import { finalize } from "rxjs";
import SelectWithSearchComponent from "@components/input/SelectWithSearch.component";

interface FilterAdsFormModalProps {
  handleClose: () => void;
  setOpenedModalType: (open: OpenedModalType) => void;
}

const FilterAdsFormModal: React.FC<FilterAdsFormModalProps> = ({ handleClose, setOpenedModalType }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [affiliates, setAffiliates] = useState<SelectItem[]>([]);
  const [adsToPublishUnfiltered, setAdsToPublishUnfiltered] = useState<AdModel[]>([]);
  const [loadingAffiliates, setLoadingAffiliates] = useState(false);

  const [adsToPublish] = useObservable(adsQuery.adsToPublish$);
  const [adsToPublishFilters] = useObservable(adsQuery.adsToPublishFilters$);
  const [loadingAdsToPublish] = useObservable(adsQuery.loadingAdsToPublish$);
  const debouncedGetFilteredAds = useMemo(
    () =>
      debounce((filters: AdsToPublishFilters) => {
        adsService.getFilteredAdsToPublish(filters).subscribe({
          error: (err) => enqueueSnackbar(err.text, err.options),
          next: (ads) => {
            if (
              filters.affiliateIds.length &&
              !filters.contractType.length &&
              !filters.location.length &&
              !filters.excludedAdIds.length &&
              !filters.commaSeparatedReferences
            ) {
              setAdsToPublishUnfiltered(ads);
            }
          },
        });
      }, 300),
    [enqueueSnackbar]
  );

  useEffect(() => {
    setLoadingAffiliates(true);
    affiliatesService
      .getAffiliates()
      .pipe(finalize(() => setLoadingAffiliates(false)))
      .subscribe({
        next: (af) => setAffiliates(af.data.map((a) => ({ label: a.name, value: a.id }))),
        error: (err) => enqueueSnackbar(err.text, err.options),
        complete: () => setLoadingAffiliates(false),
      });
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (!loadingAffiliates && adsToPublishFilters.affiliateIds.length) {
      debouncedGetFilteredAds(adsToPublishFilters);
    }
  }, [adsToPublishFilters, debouncedGetFilteredAds, loadingAffiliates]);

  const adsToPublishFiltered = useMemo(() => {
    return adsToPublishFilters.affiliateIds.length ? adsToPublish.filter((ad) => !adsToPublishFilters.excludedAdIds?.includes(ad.id)) : [];
  }, [adsToPublish, adsToPublishFilters.affiliateIds.length, adsToPublishFilters.excludedAdIds]);

  useEffect(() => {
    adsService.updateAdsToPublishSelected(adsToPublishFiltered);
  }, [adsToPublishFiltered]);

  const handleFilterChange = (filterType: keyof AdsToPublishFilters, values: SelectItem[] | string) => {
    const newFilters = {
      [filterType]: values,
      excludedAdIds: [],
    };

    adsService.updateAdsToPublishFilters(newFilters);
  };

  const locations = useMemo(() => {
    return Array.from(new Set(adsToPublishFiltered.map((ad) => ad.location))).map((location) => ({ label: location, value: location }));
  }, [adsToPublishFiltered]);

  const contractTypes = useMemo(() => {
    return Array.from(new Set(adsToPublishUnfiltered.map((ad) => ad.contractType)))
      .filter((contractType) => !!contractType)
      .map((contractType) => ({
        label: contractType!,
        value: contractType!,
      }));
  }, [adsToPublishUnfiltered]);

  return (
    <ModalComponent
      handleClose={() => handleClose()}
      fullWidth={true}
      maxWidth="sm"
      titleLeft={false}
      scroll="paper"
      ariaLabel="Modal exemple"
      textAlign="center"
      content={
        <Stack width="100%" justifyContent="center" alignItems="center" spacing={3} marginY={1}>
          <Typography
            variant="h1"
            sx={{
              fontSize: "20px",
              fontWeight: "700",
              textAlign: "center",
              marginBottom: "10px",
            }}>
            {t("ads.details.modals.publish.title_publish_announcement")}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              textAlign: "center",
              marginBottom: "10px",
              opacity: "0.5",
            }}>
            {t("ads.details.modals.publish.content_publish_announcement")}
          </Typography>
          <Stack width="50%">
            <SelectWithSearchComponent
              readOnly={loadingAffiliates}
              handleChange={(value) => {
                adsService.updateAdsToPublishFilters({
                  affiliateIds: value ? [value] : [],
                  contractType: [],
                  location: [],
                  excludedAdIds: [],
                  commaSeparatedReferences: "",
                });
              }}
              items={affiliates}
              value={adsToPublishFilters.affiliateIds[0]}
              placeholder={t("ads.details.modals.publish.select_companies")}
              error={!adsToPublishFilters.affiliateIds?.length ? t("errors.required") : undefined}
            />
          </Stack>
          <Stack width="50%">
            <MultiSelectWithSearchComponent
              items={contractTypes}
              disabled={!adsToPublishFilters.affiliateIds?.length || loadingAffiliates}
              values={adsToPublishFilters.contractType ?? []}
              handleChange={(values) => handleFilterChange("contractType", values)}
              placeholder={t("ads.details.modals.publish.select_contract_types")}
            />
          </Stack>
          <Stack width="50%">
            <MultiSelectWithSearchComponent
              items={locations}
              disabled={!adsToPublishFilters.affiliateIds?.length || loadingAffiliates}
              values={adsToPublishFilters.location ?? []}
              handleChange={(values) => handleFilterChange("location", values)}
              placeholder={t("ads.details.modals.publish.select_locations")}
            />
          </Stack>

          <Stack width="50%">
            <AIOTextfieldComponent
              title={t("ads.details.modals.publish.select_references")}
              readonly={!adsToPublishFilters.affiliateIds?.length || loadingAffiliates}
              value={adsToPublishFilters.commaSeparatedReferences}
              onChange={(value) => handleFilterChange("commaSeparatedReferences", value)}
              placeholder={t("ads.details.modals.publish.select_references_placeholder")}
            />
          </Stack>
          {loadingAdsToPublish || loadingAffiliates ? (
            <CircularProgress size={20} />
          ) : (
            <Typography
              variant="body1"
              sx={{
                textDecoration: adsToPublishFiltered.length ? "underline" : "none",
                cursor: adsToPublishFiltered.length ? "pointer" : "default",
                color: adsToPublishFiltered.length ? Colors.primary : Colors.secondaryText,
              }}
              onClick={() => adsToPublishFiltered.length && setOpenedModalType("filters&adSelection")}>
              {t("ads.details.modals.publish.selected_ads_count", { count: adsToPublishFiltered.length })}
            </Typography>
          )}
        </Stack>
      }
      actions={
        <>
          <AIOButtonComponent
            onClick={() => {
              setOpenedModalType("confirmPublish");
            }}
            variant="contained"
            title={t("ads.details.modals.publish.publish_to_france_travail")}
            disabled={!adsToPublishFiltered.length || !adsToPublishFilters.affiliateIds?.length || loadingAdsToPublish || loadingAffiliates}
          />
        </>
      }
    />
  );
};

export default FilterAdsFormModal;
