import { createStore, withProps } from "@ngneat/elf";
import { withEntities, withUIEntities } from "@ngneat/elf-entities";
import { withPagination } from "@ngneat/elf-pagination";
import { withRequestsStatus } from "@ngneat/elf-requests";
import {
  AdModel,
  AdsFilters,
  AdsSort,
  AdsSortField,
  AdStats,
  AdsToPublishFilters,
  AdUi,
  MultiDiffusionAdModel,
  MultiDiffusionAdsFilters,
  MultiDiffusionAdStats,
  MultiDiffusionAdStatusEnum,
} from "@store/ads/ads.model";

export interface PaginationDataFromServer {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  itemCount: number;
  page: number;
  pageCount: number;
  take: number;
}

interface AdsStoreProps {
  stats: AdStats;
  filters: AdsFilters;
  sort: AdsSort;
  adsToPublish: AdModel[];
  loadingAdsToPublish: boolean;
  adsToPublishSelected: AdModel[];
  adsToPublishFilters: AdsToPublishFilters;
  multiDiffusionAds: {
    data: MultiDiffusionAdModel[];
    meta: PaginationDataFromServer;
  };
  multiDiffusionAdsStats: MultiDiffusionAdStats;
  multiDiffusionAdsFilters: MultiDiffusionAdsFilters;
}

export const adsStore = createStore(
  { name: "ads" },
  withProps<AdsStoreProps>({
    stats: {},
    filters: { isArchived: false },
    sort: { field: AdsSortField.NAME },
    adsToPublish: [],
    loadingAdsToPublish: false,
    adsToPublishSelected: [],
    adsToPublishFilters: {
      affiliateIds: [],
      contractType: [],
      location: [],
      excludedAdIds: [],
      commaSeparatedReferences: "",
    },
    multiDiffusionAds: {
      data: [],
      meta: {
        hasNextPage: false,
        hasPreviousPage: false,
        itemCount: 0,
        page: 1,
        pageCount: 0,
        take: 24,
      },
    },
    multiDiffusionAdsStats: Object.values(MultiDiffusionAdStatusEnum).reduce((acc, status) => {
      acc[status] = 0;
      return acc;
    }, {} as MultiDiffusionAdStats),

    multiDiffusionAdsFilters: {
      status: undefined,
      affiliateIds: [],
      search: undefined,
    },
  }),
  withEntities<AdModel>(),
  withUIEntities<AdUi>(),
  withRequestsStatus(),
  withPagination()
);
