import AIOSearchComponent from "@components/input/AIOSearch.component";
import { Colors } from "@constants/colors.constant";
import { Sizes } from "@constants/sizes.constant";
import { Box, Button, debounce, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useObservable } from "@ngneat/react-rxjs";
import AdsApplicationContainer from "@screens/auth/common/ads/components/AdsApplication.container";
import AIApplicationsEvaluationButton from "@screens/auth/common/ads/components/AIApplicationsEvaluation.button";
import EmailCandidateModal from "@screens/auth/common/ads/components/applicationDetails/EmailCandidate.modal";
import { AdDetailsApplicationsList, adDetailsQuery, adDetailsService } from "@store/ads/details";
import { AiApplicationEvaluationResponse, Application, ApplicationsListEnum } from "@store/applications";
import { twilioAiCallsQuery, twilioAiCallsService } from "@store/twilioAiCalls";
import socketUtilsInstance from "@utils/socket.utils";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { DragDropContext, DragStart, DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { Outlet, useParams } from "react-router-dom";
import { forkJoin, Observable, tap } from "rxjs";
import AdsHistoryModal from "./AdsHistory.modal";
import AIOButtonComponent from "@components/Button.component";
import AiCriteriaEvaluationModal from "@components/modal/AiCriteriaEvaluation.modal";

const GPT_EVALUATION_LIMITATION = 20;

const AdsApplicationTab = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { adId } = useParams();
  const { t } = useTranslation();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));

  const [activeIds, setActiveIds] = useState<string[]>([]);

  const [evaluationLoading, setEvaluationLoading] = useState(false);
  const [inAnimationApplications, setInAnimationApplications] = useState<string[]>([]);

  const [{ adApplications: applications }] = useObservable(adDetailsQuery.applications$);
  const [{ twilioAiCalls }] = useObservable(twilioAiCallsQuery.twilioAiCalls$);

  const [applicationsByCategoryState, setApplicationsByCategoryState] = useState<AdDetailsApplicationsList[] | undefined>(applications);
  const [checkedApplications, setCheckedApplications] = useState<{ columnName: string; id: string }[]>([]);
  const [search, setSearch] = useState<string>("");
  const [openEmailCandidate, setOpenEmailCandidate] = useState<Application[] | false>(false);

  const [isCriteriaModalOpen, setIsCriteriaModalOpen] = useState(false);

  const [{ adDetails: ad }] = useObservable(adDetailsQuery.details$);

  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);

  useEffect(() => {
    if (adId) {
      adDetailsService.getAdApplications(adId, search).subscribe({
        error: (err: any) => enqueueSnackbar(err.text, err.options),
      });
      twilioAiCallsService.getAiCalls(adId).subscribe({
        next: (twilioAiCallsResp) => {
          twilioAiCallsService.handleSocketListeners(twilioAiCallsResp);
        },
        error: (err: any) => enqueueSnackbar(err.text, err.options),
      });
    }
  }, [adId, enqueueSnackbar, search]);

  useEffect(() => {
    setApplicationsByCategoryState(
      applications?.map((category) => {
        if (twilioAiCalls.length) {
          category.applications = category.applications.map((application) => {
            const twilioAiCall = twilioAiCalls.find((call) => call.application.id === application.id);

            return {
              ...application,
              twilioAiCall,
            };
          });
        }
        return category;
      })
    );
  }, [applications, twilioAiCalls]);

  useEffect(() => {
    if (!socketUtilsInstance.isConnected()) {
      socketUtilsInstance.connect();
    }
    return () => {
      socketUtilsInstance.disconnect();
    };
  }, []);

  const AIEvaluableApplications = useMemo(() => {
    return (
      applicationsByCategoryState
        ?.find((category) => category.name === ApplicationsListEnum.TO_BE_PROCESSED)
        ?.applications.slice(0, GPT_EVALUATION_LIMITATION) || []
    );
  }, [applicationsByCategoryState]);

  const toBeProcessedApplications = useMemo(() => {
    return applicationsByCategoryState?.find((category) => category.name === ApplicationsListEnum.TO_BE_PROCESSED)?.applications || [];
  }, [applicationsByCategoryState]);

  const sortApplicationsByDate = (applicationsByCategory: AdDetailsApplicationsList[]): AdDetailsApplicationsList[] => {
    applicationsByCategory.forEach((category) => {
      category.applications = category.applications.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    });
    return applicationsByCategory;
  };

  const findApplicationById = (id: string): Application | undefined => {
    let _application;
    applicationsByCategoryState?.forEach((category) => {
      category.applications.forEach((application) => {
        if (application.id === id) {
          _application = application;
        }
      });
    });
    return _application;
  };

  const handleDragStart = (start: DragStart) => {
    const { draggableId } = start;

    const activeCheckedApplication = checkedApplications.find((application) => application.id === draggableId);
    if (activeCheckedApplication) {
      setActiveIds(checkedApplications.filter((x) => x.columnName === activeCheckedApplication.columnName).map((application) => application.id));
    } else {
      setActiveIds([draggableId as string]);
    }
  };

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }
    const containerDroppedFrom = source.droppableId as ApplicationsListEnum;
    const containerDroppedOn = destination.droppableId as ApplicationsListEnum;

    if (containerDroppedFrom !== containerDroppedOn) {
      setApplicationsByCategoryState((prev) => {
        if (!prev) return prev;
        const activeApplications = activeIds.map((activeId) => findApplicationById(activeId)).filter(Boolean);
        if (!activeApplications.length) return prev;

        prev.forEach((category) => {
          if (category.name === containerDroppedFrom) {
            category.applications = category.applications.filter((application) => !activeIds.some((id) => id === application.id));
          }
        });
        prev.forEach((category) => {
          if (category.name === containerDroppedOn) {
            category.applications = category.applications.concat(activeApplications as Application[]);
          }
        });

        return [...sortApplicationsByDate(prev)];
      });

      const applicationsObservables: Observable<Application>[] = activeIds
        .filter((activeId) => {
          const application = findApplicationById(activeId);
          return application?.list && application?.list !== containerDroppedOn;
        })
        .map((activeId) => {
          return adDetailsService.editAdApplicationCategory(activeId, containerDroppedOn);
        });

      forkJoin(applicationsObservables)
        .pipe(
          tap((a: Application[]) => {
            if (containerDroppedOn === ApplicationsListEnum.NOT_SELECTED) setOpenEmailCandidate(a);
          })
        )
        .subscribe();

      setCheckedApplications((prev) => prev.filter((x) => !activeIds.includes(x.id)));
    }
    setActiveIds([]);
  };

  const handleAutomaticallyMoved = (newCategory: string, response: AiApplicationEvaluationResponse) => {
    if (!response.application) return;

    setApplicationsByCategoryState((prev) => {
      if (!prev) return prev;

      prev.forEach((category) => {
        category.applications = category.applications.filter((a) => a.id !== response.application!.id);

        if (category.name === newCategory) {
          category.applications = category.applications.filter((a) => a.id !== response.application!.id);
          category.applications = category.applications.concat({
            ...response.application!,
            list: newCategory,
            aiEvaluation:
              !!response.mark && !!response.applicationList
                ? {
                    mark: response.mark,
                    analysis: response.analysis ?? "",
                    applicationList: response.applicationList,
                  }
                : undefined,
          });
        }
      });

      return [...sortApplicationsByDate(prev)];
    });
  };

  return (
    <Stack overflow="auto" spacing={0.5} position="relative" width="100%" flex={1}>
      <Stack flex={1} pt={breakpointDownSM ? "20px" : "40px"} justifyContent="space-between" width="100%" spacing="60px" sx={{ overflowY: "hidden" }}>
        <Stack width="100%" position="relative" height="38px">
          <Stack
            width={`calc(100% - ${breakpointDownMD ? 0 : Sizes.leftBar}px)`}
            direction={"row"}
            position="fixed"
            left={breakpointDownMD ? 0 : `${Sizes.leftBar}px`}
            right={0}
            alignItems="center"
            justifyContent="space-between"
            overflow={breakpointDownMD ? "auto" : undefined}
            spacing="10px">
            <Box
              ml={breakpointDownMD ? undefined : "40px !important"}
              pl={breakpointDownMD ? "10px" : undefined}
              maxWidth="500px"
              minWidth={"240px"}
              width="100%">
              <AIOSearchComponent
                placeholder={t("ads.details.applyTab.searchPlaceholder")}
                fullWidth
                width="100%"
                onChange={debounce((value?: string) => setSearch(value || ""), 400)}
              />
            </Box>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Box>
                <Button
                  aria-label="light"
                  onClick={() => setIsHistoryModalOpen(true)}
                  startIcon={<img style={{ width: "15px" }} src="/images/ia_sparkle.png" alt="history" />}>
                  <Typography fontWeight={500} fontSize="15px" color={Colors.secondaryText} noWrap>
                    {t("ads.details.applyTab.callHistory", { count: checkedApplications.length })}
                  </Typography>
                </Button>
              </Box>
              {AIEvaluableApplications.length > 0 && (
                <AIOButtonComponent
                  title={ad?.criterias?.length ? t("ads.details.applyTab.aiSorting.criterias") : t("ads.details.applyTab.aiSorting.addCriteriaBtn")}
                  variant="contained"
                  onClick={() => setIsCriteriaModalOpen(true)}
                />
              )}
              <Box mr={breakpointDownMD ? undefined : "40px !important"} pr={breakpointDownMD ? "10px" : undefined}>
                <AIApplicationsEvaluationButton
                  loading={evaluationLoading}
                  setLoading={setEvaluationLoading}
                  applicationsByCategoryState={applicationsByCategoryState}
                  evaluableApplications={AIEvaluableApplications}
                  handleAutomaticallyMoved={handleAutomaticallyMoved}
                  toBeProcessedApplications={toBeProcessedApplications.length}
                  updateInAnimationApplications={setInAnimationApplications}
                />
              </Box>
            </Stack>
          </Stack>
        </Stack>
        <Box pb={breakpointDownSM ? "20px" : "40px"} width="100%" flex={1} px={breakpointDownMD ? "20px" : "40px"}>
          <Stack direction="row" width="100%" height={`calc(100% - ${breakpointDownSM ? 20 : 0}px - 20px )`} position="relative">
            <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
              <Box position="absolute" width="100%" textAlign="left" bottom={breakpointDownSM ? "-24px" : "-30px"} left="0px" alignSelf="flex-end">
                <Typography alignSelf="flex-end" fontWeight={300} fontSize="12px" color={Colors.secondaryText}>
                  {t("ads.details.applyTab.warning")}
                </Typography>
              </Box>
              {applicationsByCategoryState?.map((applicationList) => (
                <AdsApplicationContainer
                  items={applicationList}
                  key={applicationList.name}
                  columnName={applicationList.name}
                  activeIds={activeIds}
                  adId={adId}
                  applicationCategories={applicationsByCategoryState.map((x) => x.name)}
                  setCheckedApplications={setCheckedApplications}
                  checkedApplications={checkedApplications}
                  handleEmailCandidate={setOpenEmailCandidate}
                  disableDrag={evaluationLoading}
                  inAnimationApplications={inAnimationApplications}
                />
              ))}
            </DragDropContext>
            <Box height="100%" minWidth="0.1px" ml="0px" />
          </Stack>
        </Box>
      </Stack>
      <Outlet />
      {!!openEmailCandidate && <EmailCandidateModal applications={openEmailCandidate} handleClose={() => setOpenEmailCandidate(false)} />}
      {!!ad && isCriteriaModalOpen && (
        <AiCriteriaEvaluationModal
          handleCloseModal={() => {
            setIsCriteriaModalOpen(false);
          }}
          adDetails={ad}
        />
      )}
      {!!isHistoryModalOpen && <AdsHistoryModal handleClose={() => setIsHistoryModalOpen(false)} />}
    </Stack>
  );
};

export default AdsApplicationTab;
