import AIOButtonComponent from "@components/Button.component";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { OpenedModalType } from "../PublishModalsManager";

interface PublishButtonActionProps {
  action: () => void;
  selectedDate: Date | null;
  selectedTime: Date | null;
  setOpenedModalType: (type: OpenedModalType) => void;
  loading: boolean;
}

const PublishButtonAction = ({ action, selectedDate, selectedTime, setOpenedModalType, loading }: PublishButtonActionProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack
      direction={breakpointDownSM ? "column" : "row"}
      justifyContent="space-between"
      alignItems="center"
      width="50%"
      marginTop={"1rem"}
      spacing={2}>
      <AIOButtonComponent
        variant="contained"
        onClick={action}
        disabled={!selectedDate || !selectedTime || loading}
        title={t("ads.details.modals.publish.later.submit_button")}
      />
      <AIOButtonComponent
        ariaLabel="light"
        onClick={() => setOpenedModalType("confirmPublish")}
        title={t("ads.details.modals.publish.confirm.cancel")}
      />
    </Stack>
  );
};

export default PublishButtonAction;
