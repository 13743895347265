import { useEffect, useMemo, useState } from "react";
import FilterAdsFormModal from "./FilterAdsForm.modal";
import ModalComponent from "../Modal.component";
import { useTranslation } from "react-i18next";
import SelectedAdsModal from "./SelectAds.modal";
import ConfirmPublishAdsModal from "./ConfirmPublishAds.modal";
import PublishLaterModal from "./LaterPublish.modal";
import PublishButtonAction from "./buttonActions/Publish.buttonAction";
import CloseButtonAction from "./buttonActions/Close.buttonAction";
import PublishedNowModal from "./Published.modal";
import ProgrammeAdPublishedModal from "./ProgrammePublishedAd.modal";
import { Box } from "@mui/material";
import AIOButtonComponent from "@components/Button.component";
import { adsQuery, adsService, MultiDiffusionAdMediaEnum, MultiDiffusionAdStatusEnum } from "@store/ads";
import { useSnackbar } from "notistack";
import { useObservable } from "@ngneat/react-rxjs";
import dayjsUtils from "@utils/dayjs.utils";
import { finalize } from "rxjs";

interface PublishModalManagerProps {
  isOpen: boolean;
  onClose: () => void;
  singular?: boolean;
  singularAdId?: string;
  onPublish?: (status: MultiDiffusionAdStatusEnum) => void;
}

export type OpenedModalType = "filters" | "filters&adSelection" | "confirmPublish" | "publishedNow" | "publishedLater" | "publishLater" | null;

const PublishModalsManager = ({ isOpen, onClose, singular = false, singularAdId, onPublish }: PublishModalManagerProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<Date | null>(null);
  const [openedModalType, setOpenedModalType] = useState<OpenedModalType>(null);
  const [loadingPublish, setLoadingPublish] = useState(false);

  const [adsToPublishSelected] = useObservable(adsQuery.adsToPublishSelected$);

  useEffect(() => {
    if (isOpen) {
      if (singular) {
        setOpenedModalType("confirmPublish");
      } else {
        setOpenedModalType("filters");
      }
    } else {
      setOpenedModalType(null);
    }
  }, [isOpen, singular]);

  const handleClose = () => {
    setOpenedModalType(null);
    onClose();
  };

  const publishDate = useMemo(() => {
    if (!selectedDate) return null;
    let date = dayjsUtils(selectedDate).toDate();
    if (selectedTime) {
      date = dayjsUtils(date)
        .hour(dayjsUtils(selectedTime).hour())
        .minute(dayjsUtils(selectedTime).minute())
        .second(dayjsUtils(selectedTime).second())
        .toDate();
    }
    return date;
  }, [selectedDate, selectedTime]);

  if (!isOpen) {
    return null;
  }

  const handlePublishNow = () => {
    setLoadingPublish(true);

    if (singular && singularAdId) {
      return adsService
        .publishAds([singularAdId], [MultiDiffusionAdMediaEnum.FRANCE_TRAVAIL])
        .pipe(finalize(() => setLoadingPublish(false)))
        .subscribe({
          next: () => {
            setOpenedModalType("publishedNow");
            onPublish?.(MultiDiffusionAdStatusEnum.PENDING);
          },
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    }

    adsService
      .publishAds(
        adsToPublishSelected?.map((ad) => ad.id),
        [MultiDiffusionAdMediaEnum.FRANCE_TRAVAIL] //TODO : evol: add other medias
      )
      .pipe(finalize(() => setLoadingPublish(false)))
      .subscribe({
        next: () => {
          setOpenedModalType("publishedNow");
          onPublish?.(MultiDiffusionAdStatusEnum.PENDING);
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const handlePublishLater = () => {
    if (!publishDate) return;

    setLoadingPublish(true);

    if (singular && singularAdId) {
      return adsService
        .publishAds([singularAdId], [MultiDiffusionAdMediaEnum.FRANCE_TRAVAIL], publishDate)
        .pipe(finalize(() => setLoadingPublish(false)))
        .subscribe({
          next: () => {
            setOpenedModalType("publishedLater");
            onPublish?.(MultiDiffusionAdStatusEnum.PROGRAMMED);
          },
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    }

    adsService
      .publishAds(
        adsToPublishSelected?.map((ad) => ad.id),
        [MultiDiffusionAdMediaEnum.FRANCE_TRAVAIL], //TODO : evol: add other medias
        publishDate
      )
      .pipe(finalize(() => setLoadingPublish(false)))
      .subscribe({
        next: () => {
          setOpenedModalType("publishedLater");
          onPublish?.(MultiDiffusionAdStatusEnum.PROGRAMMED);
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const renderModal = () => {
    switch (openedModalType) {
      case "filters":
      case "filters&adSelection":
        return (
          <>
            <FilterAdsFormModal handleClose={handleClose} setOpenedModalType={setOpenedModalType} />
            {openedModalType === "filters&adSelection" && (
              <ModalComponent
                handleClose={() => setOpenedModalType("filters")}
                title={t("ads.details.modals.publish.title_annoucement_selected")}
                fullWidth={true}
                maxWidth="lg"
                paddingButton="24px"
                scroll="body"
                titleLeft={true}
                content={
                  <Box height="60vh" overflow="auto" className="scrollable">
                    <SelectedAdsModal handleClose={() => setOpenedModalType("filters")} />
                  </Box>
                }
                actions={
                  <AIOButtonComponent
                    title={t("ads.details.modals.publish.validate_selection")}
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpenedModalType("filters")}
                  />
                }
              />
            )}
          </>
        );
      case "confirmPublish":
        return (
          <ConfirmPublishAdsModal
            handleClose={() => (singular ? handleClose() : setOpenedModalType("filters"))}
            setOpenedModalType={setOpenedModalType}
            singular={singular}
            onPublishNow={handlePublishNow}
            onPublishLater={() => setOpenedModalType("publishLater")}
            loading={loadingPublish}
          />
        );
      case "publishLater":
        return (
          <ModalComponent
            handleClose={() => setOpenedModalType("confirmPublish")}
            maxWidth="sm"
            content={<PublishLaterModal singular={false} onDateChange={setSelectedDate} onTimeChange={setSelectedTime} />}
            actions={
              <PublishButtonAction
                setOpenedModalType={setOpenedModalType}
                selectedDate={selectedDate}
                selectedTime={selectedTime}
                action={handlePublishLater}
                loading={loadingPublish}
              />
            }
          />
        );

      case "publishedNow":
      case "publishedLater":
        return (
          <ModalComponent
            handleClose={handleClose}
            maxWidth="sm"
            content={
              openedModalType === "publishedNow" ? <PublishedNowModal singular={singular} /> : <ProgrammeAdPublishedModal singular={singular} />
            }
            actions={<CloseButtonAction onClose={handleClose} />}
          />
        );
      default:
        return null;
    }
  };

  return <>{renderModal()}</>;
};

export default PublishModalsManager;
