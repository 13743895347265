import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { finalize } from "rxjs";
import { useSnackbar } from "notistack";

import { adDetailsQuery, adDetailsService } from "@store/ads/details";

import { checkIfErrors, FieldValidationType, getFieldError } from "@utils/yup.utils";
import { REJECT_TEXT_STORAGE_KEY } from "@constants/localStorage.constant";

import { Application, ApplicationsListEnum, ApplicationsListEnumFunctions, SendCandidateEmail } from "@store/applications";
import ApplicationCard from "@components/card/Application.card";
import ModalComponent from "@components/modal/Modal.component";
import AIOButtonComponent from "@components/Button.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import { Colors } from "@constants/colors.constant";
import { subscriptionsQuery } from "@store/subscriptions";
import SeeSubscriptionsModal from "@components/subscriptions/modal/SeeSubscriptions.modal";
import { subscriptions, SubscriptionTypeEnum } from "@store/subscriptions/subscriptions.model";
import { sessionQuery } from "@store/session";
import { UserRoleEnum } from "@store/users";
import { useObservable } from "@ngneat/react-rxjs";

interface EmailCandidateModalProps {
  applications: Application[];
  column?: ApplicationsListEnum.NOT_SELECTED;
  handleClose: () => void;
}

const EmailCandidateModal = (props: EmailCandidateModalProps) => {
  const { applications, column = ApplicationsListEnum.NOT_SELECTED, handleClose } = props;

  const [{ adDetails: ad }] = useObservable(adDetailsQuery.details$);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<SendCandidateEmail>({
    type: ApplicationsListEnumFunctions.emailTypes[column],
    emails: applications.map((a) => a.candidate?.email || ""),
    text: localStorage.getItem(REJECT_TEXT_STORAGE_KEY) || undefined,
  });
  const [openSeeSubscriptionsModal, setOpenSeeSubscriptionsModal] = useState(false);

  const updateData = (name: string) => (value: any) => setData((state) => ({ ...state, [name]: value }));

  const updateDataEmail = (index: number) => (value: string) => {
    if (!data.emails) return;
    const emails = [...data.emails];
    emails[index] = value;
    setData((state) => ({ ...state, emails }));
  };

  const errors = {
    email: getFieldError(data.emails, FieldValidationType.REQUIRED_EMAIL_ARRAY),
  };

  const errorsArray =
    data.emails?.map((email) => {
      return { email: getFieldError(email, FieldValidationType.REQUIRED_EMAIL) };
    }) || [];

  const handleSend = () => {
    if (!data.type || !data.emails || !ad?.id) return;

    if (data.saveText && !!data.text) {
      localStorage.setItem(REJECT_TEXT_STORAGE_KEY, data.text);
    }
    setLoading(true);
    adDetailsService
      .sendEmailsToCandidates(
        applications.map((a) => a.id),
        {
          ...data,
          text: data.text || t(`ads.details.applyTab.emailCandidate.defaultContent.${column}`),
        },
        ad?.id
      )
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t(`ads.details.applyTab.success.emailSent`, { count: applications.length }), { variant: "success" });
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  if (!adDetailsQuery.managedByTwinin && !subscriptionsQuery.sessionUserSubscriptionOngoing && sessionQuery.role !== UserRoleEnum.ADMIN)
    return (
      <>
        <ModalComponent
          handleClose={handleClose}
          title={t(`ads.details.applyTab.emailCandidate.title.NOT_SELECTED`)}
          icon={<img width={60} alt="warning" src="/images/illus_warning.png" />}
          content={
            <Stack mt="20px" spacing={3} px={"50px"} pb="20px">
              <Typography textAlign={"center"} lineHeight={1.5}>
                {t(`ads.details.applyTab.emailCandidate.noSubscriptionDescription`)}
              </Typography>
            </Stack>
          }
          actions={
            <AIOButtonComponent
              title={t("subscriptions.seeSubscriptions")}
              variant="contained"
              ariaLabel="modal"
              onClick={() => setOpenSeeSubscriptionsModal(true)}
            />
          }
          maxWidth="sm"
        />
        {openSeeSubscriptionsModal && (
          <SeeSubscriptionsModal
            subscriptions={subscriptions.filter((s) => s.type === SubscriptionTypeEnum.COMPANY || s.type === SubscriptionTypeEnum.PROFESSIONAL)}
            handleClose={() => setOpenSeeSubscriptionsModal(false)}
          />
        )}
      </>
    );

  return (
    <ModalComponent
      handleClose={handleClose}
      title={t(`ads.details.applyTab.emailCandidate.title.${column}`)}
      titleLeft
      content={
        <Stack mt="20px" spacing={3}>
          <Stack spacing={3}>
            {applications.map((application, i) => (
              <React.Fragment key={application.id}>
                <ApplicationCard applyInfos={application} downloadOnly />
                <AIOTextfieldComponent
                  required
                  error={errorsArray[i].email}
                  title={t("ads.details.applyTab.emailCandidate.email")}
                  value={data.emails ? data.emails[i] : ""}
                  onChange={updateDataEmail(i)}
                />
              </React.Fragment>
            ))}
          </Stack>
          <Stack spacing="2px">
            <Typography fontSize="12px" color={Colors.secondaryText}>
              {t("ads.details.applyTab.emailCandidate.content")}
            </Typography>
            <Typography fontSize="12px" whiteSpace="pre-line" color={Colors.secondaryText} fontStyle="italic" pb="5px">
              {t("ads.details.applyTab.emailCandidate.contentDescription")}
            </Typography>
            <AIOTextfieldComponent
              multiline
              minRows={6}
              value={data.text || t(`ads.details.applyTab.emailCandidate.defaultContent.${column}`)}
              onChange={updateData("text")}
            />
          </Stack>
          <FormControlLabel
            sx={{ width: "fit-content", opacity: !data.text ? 0.4 : 1 }}
            label={
              <Typography fontSize="14px" ml="6px">
                {t("ads.details.applyTab.emailCandidate.save")}
              </Typography>
            }
            disabled={!data.text}
            onChange={(e, checked) => updateData("saveText")(checked)}
            checked={!!data.saveText}
            control={<Checkbox size="small" />}
          />
        </Stack>
      }
      maxWidth="md"
      actions={
        <Stack direction="row" alignItems="center" spacing={2}>
          <AIOButtonComponent title={t("ads.details.applyTab.emailCandidate.skip")} variant="text" ariaLabel="modal" onClick={handleClose} />
          <AIOButtonComponent
            title={t("global.send")}
            variant="contained"
            disabled={checkIfErrors(errors) || loading}
            ariaLabel="modal"
            onClick={handleSend}
          />
        </Stack>
      }
    />
  );
};

export default EmailCandidateModal;
