import { Box, CircularProgress, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffectFn } from "@ngneat/effects-hooks";
import { useObservable } from "@ngneat/react-rxjs";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { sessionQuery } from "@store/session";
import { AdsFilterArchived, adsQuery, adsService, AdsSortField, AdStatProgress, searchAdsEffect } from "@store/ads";

import { Colors } from "@constants/colors.constant";
import { InfiniteScrollContainer, VirtualScrollItem } from "@utils/infinitescroll.utils";
import { AdRoutes } from "@utils/routes.utils";

import BackToTopButton from "@components/BackToTopButton.component";
import AIOButtonComponent from "@components/Button.component";
import AdCard from "@components/card/Ad.card";
import StatCardComponent from "@components/card/StatCard.component";
import AIOSearchComponent from "@components/input/AIOSearch.component";
import ClientsFilterComponent from "@components/input/ClientsFilterComponent";
import MultiSelectWithSearchComponent from "@components/input/MultiSelectWithSearch.component";
import AIOSelectComponent, { SelectItem } from "@components/input/Select.component";
import { RELOAD_ADS } from "@constants/events.constant";
import { emitOnce } from "@ngneat/elf";
import ManageAdModal from "@screens/auth/common/ads/components/ManageAd.modal";
import { UserRoleEnum } from "@store/users";
import ConfyAdsModal from "./components/ConfyAds.modal";

import PublishModalsManager from "@components/modal/publishAds/PublishModalsManager";

interface StatTooltipComponentProps {
  title: string;
  description: string;
}

export const StatTooltipComponent = (props: StatTooltipComponentProps) => {
  return (
    <Stack spacing={0.25}>
      <Typography fontSize={12} fontWeight="500">
        {props.title}
      </Typography>
      <Typography fontSize={12} fontWeight="300" lineHeight={1.25} whiteSpace="pre-line">
        {props.description}
      </Typography>
    </Stack>
  );
};

const Ads = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));
  const breakpointDownLG = useMediaQuery(theme.breakpoints.down("lg"));

  const searchAds = useEffectFn(searchAdsEffect);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 24;
  const scrollableAdsRef = useRef<HTMLDivElement | null>(null);
  const scrollableAdsMobileRef = useRef<HTMLDivElement | null>(null);
  const scrollableAdsClientRef = useRef<HTMLDivElement | null>(null);

  const [clientViewClient] = useObservable(sessionQuery.clientViewClient$);
  const [affiliateIds] = useObservable(sessionQuery.affiliateIds$);
  const [filters] = useObservable(adsQuery.filters$);

  const [affiliates] = useState<SelectItem[]>(sessionQuery.affiliatesItems);
  const [openConfyAdsModal, setOpenConfyAdsModal] = useState(false);
  const [openAddAdModal, setOpenAddAdModal] = useState(false);
  const [openPublishModalManager, setOpenPublishModalManager] = useState(false);

  const [sort] = useObservable(adsQuery.sort$);
  const [{ stats, error: statsError, loading: statsLoading }] = useObservable(adsQuery.stats$);
  const [{ ads, error: adsError, loading: adsLoading }] = useObservable(adsQuery.ads$);
  const [adsPaginationData] = useObservable(adsQuery.adsPaginationData$);

  useEffect(() => {
    emitOnce(() => {
      adsService.resetStore();
      if (clientViewClient) {
        adsService.setFilters({ affiliateIds: [{ value: clientViewClient?.id, label: "" }] });
      }
    });
  }, [clientViewClient]);

  useEffect(() => {
    if (adsError) enqueueSnackbar((adsError as any).text, (adsError as any).options);
    if (statsError) enqueueSnackbar((statsError as any).text, (statsError as any).options);
  }, [adsError, enqueueSnackbar, statsError]);

  useEffect(() => {
    if (sessionQuery.role !== UserRoleEnum.ADMIN || !!clientViewClient) {
      adsService.getAdsStats(filters).subscribe();
    }
  }, [clientViewClient, filters]);

  const getAds = useCallback(() => {
    searchAds({ filters, sort, page: currentPage, take: itemsPerPage });
  }, [filters, searchAds, sort, itemsPerPage, currentPage]);

  useEffect(() => {
    searchAds({ filters, sort, page: currentPage, take: itemsPerPage });
  }, [affiliateIds, filters, searchAds, sort, itemsPerPage, currentPage]);

  useEffect(() => {
    window.addEventListener(RELOAD_ADS, getAds);

    getAds();

    return () => {
      window.removeEventListener(RELOAD_ADS, getAds);
    };
  }, [getAds]);

  useEffect(() => {
    emitOnce(() => {
      adsService.deleteEntities();
      adsService.deleteAllPages();
    });
    setCurrentPage(1);
  }, [filters, sort, affiliateIds]);

  const handleNextPage = useCallback(() => {
    if (adsPaginationData.lastPage >= adsPaginationData.currentPage) {
      setCurrentPage(adsPaginationData.currentPage + 1);
    }
  }, [adsPaginationData.currentPage, adsPaginationData.lastPage]);

  const backToTop = () => {
    if (scrollableAdsRef.current)
      scrollableAdsRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    if (scrollableAdsMobileRef.current)
      scrollableAdsMobileRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    if (scrollableAdsClientRef.current)
      scrollableAdsClientRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  };

  const getProgress = (statProgress?: AdStatProgress) => {
    if (!statProgress || statProgress.previous === 0) return 0;
    return Math.round(((statProgress.current - statProgress.previous) / statProgress.previous) * 100);
  };

  return (
    <Stack
      pt={breakpointDownSM ? "10px" : "60px"}
      px={breakpointDownSM ? "10px" : "40px"}
      spacing={breakpointDownSM ? 0 : 4}
      flex={1}
      width="100%"
      overflow="auto">
      <Stack
        direction={breakpointDownSM ? "column" : "row"}
        alignItems={breakpointDownSM ? "flex-start" : "center"}
        spacing={2}
        justifyContent="space-between">
        <Stack alignItems="flex-start" justifyContent="space-between" width={breakpointDownSM ? "100%" : undefined}>
          <Typography fontSize={breakpointDownSM ? "30px" : "35px"} fontWeight={700}>
            {t(`ads.${sessionQuery.role === UserRoleEnum.ADMIN ? "adminTitle" : "title"}`)}
          </Typography>
          {sessionQuery.role !== UserRoleEnum.ADMIN && (
            <Stack marginTop={"10px"}>
              <Typography fontSize="14px" fontWeight="500" color={Colors.secondaryText}>
                {t("ads.subtitle")}
              </Typography>
            </Stack>
          )}
        </Stack>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          {!breakpointDownSM && (sessionQuery.role === UserRoleEnum.ADMIN || affiliates.length > 1) && (
            <Stack spacing={2} direction="row" alignItems="center">
              <Box width="350px">
                <Grid container spacing={2} alignItems="center">
                  {sessionQuery.role === UserRoleEnum.ADMIN && (
                    <Grid item>
                      <AIOButtonComponent title={"Publier"} variant="contained" color="secondary" onClick={() => setOpenPublishModalManager(true)} />
                    </Grid>
                  )}
                  <Grid item xs>
                    {sessionQuery.role !== UserRoleEnum.ADMIN ? (
                      <MultiSelectWithSearchComponent
                        handleChange={(values) => adsService.setFilters({ affiliateIds: values })}
                        startIcon={<img alt="" src="/images/icon_select_company.svg" />}
                        multiSelectedLabel={(count) => t("global.companiesCount", { count }).toString()}
                        placeholder={t("global.filterByCompanies")}
                        items={affiliates}
                        values={filters.affiliateIds ?? []}
                      />
                    ) : (
                      <ClientsFilterComponent values={affiliateIds} />
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          )}
          {!breakpointDownSM && sessionQuery.role !== UserRoleEnum.ADMIN && (
            <>
              <AIOButtonComponent variant="contained" color="secondary" onClick={() => setOpenAddAdModal(true)} title={t("ads.addJob")} />
              <AIOButtonComponent
                variant="contained"
                color="secondary"
                onClick={() => setOpenConfyAdsModal(true)}
                title={t("ads.confyAdsToTwinin")}
              />
            </>
          )}
        </Stack>
      </Stack>
      <Stack
        ref={scrollableAdsClientRef}
        overflow="auto"
        className={breakpointDownLG && sessionQuery.role !== UserRoleEnum.ADMIN ? "scrollable" : undefined}
        spacing={breakpointDownSM ? 2 : 4}
        py={breakpointDownLG && (sessionQuery.role !== UserRoleEnum.ADMIN || clientViewClient) ? "20px" : undefined}>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          {breakpointDownSM && (sessionQuery.role === UserRoleEnum.ADMIN || affiliates.length > 1) && (
            <Box width="100%">
              {sessionQuery.role !== UserRoleEnum.ADMIN ? (
                <MultiSelectWithSearchComponent
                  handleChange={(values) => adsService.setFilters({ affiliateIds: values })}
                  startIcon={<img alt="" src="/images/icon_select_company.svg" />}
                  multiSelectedLabel={(count) => t("global.companiesCount", { count }).toString()}
                  placeholder={t("global.filterByCompanies")}
                  items={affiliates}
                  values={filters.affiliateIds ?? []}
                />
              ) : (
                <ClientsFilterComponent values={affiliateIds} />
              )}
            </Box>
          )}
          {breakpointDownSM && sessionQuery.role !== UserRoleEnum.ADMIN && (
            <>
              <AIOButtonComponent variant="contained" color="secondary" onClick={() => setOpenAddAdModal(true)} title={t("ads.addJob")} />
              <AIOButtonComponent
                variant="contained"
                color="secondary"
                onClick={() => setOpenConfyAdsModal(true)}
                title={t("ads.confyAdsToTwinin")}
              />
            </>
          )}
        </Stack>

        {(sessionQuery.role !== UserRoleEnum.ADMIN || !!clientViewClient) && (
          <Box>
            <Grid container spacing={breakpointDownLG ? 1 : 3}>
              <Grid item xs={12} sm={6} lg={12 / 5}>
                <StatCardComponent
                  bgColor={Colors.stats.green}
                  label={t("ads.stats.nbViews")}
                  helper={<StatTooltipComponent title={t("ads.stats.nbViews")} description={t("ads.stats.nbViewsHelper")} />}
                  loading={statsLoading}
                  progress={getProgress(stats.nbViews)}
                  value={stats.nbViews?.current}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={12 / 5}>
                <StatCardComponent
                  bgColor={Colors.stats.purple}
                  label={t("ads.stats.uniqueVisitors")}
                  loading={statsLoading}
                  progress={getProgress(stats.uniqueVisitor)}
                  value={stats.uniqueVisitor?.current}
                />
              </Grid>
              <Grid item xs={12} sm={4} lg={12 / 5}>
                <StatCardComponent
                  bgColor={Colors.stats.red}
                  label={t("ads.stats.engagementRate")}
                  helper={<StatTooltipComponent title={t("ads.stats.engagementRate")} description={t("ads.stats.engagementRateHelper")} />}
                  extra="%"
                  loading={statsLoading}
                  progress={getProgress(stats.engagementRate)}
                  value={stats.engagementRate?.current}
                />
              </Grid>
              <Grid item xs={12} sm={4} lg={12 / 5}>
                <StatCardComponent
                  bgColor={Colors.stats.orange}
                  label={t("ads.stats.applications")}
                  loading={statsLoading}
                  value={stats.applications?.current}
                />
              </Grid>
              <Grid item xs={12} sm={4} lg={12 / 5}>
                <StatCardComponent
                  bgColor={Colors.stats.blue}
                  label={t("ads.stats.bounceRate")}
                  helper={<StatTooltipComponent title={t("ads.stats.bounceRate")} description={t("ads.stats.bounceRateHelper")} />}
                  extra="%"
                  loading={statsLoading}
                  progress={getProgress(stats.bounceRate)}
                  progressReverted
                  value={stats.bounceRate?.current}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        <Stack overflow={breakpointDownLG && sessionQuery.role !== UserRoleEnum.ADMIN ? undefined : "auto"} ref={scrollableAdsMobileRef}>
          <Stack direction="row" alignItems="center" justifyContent="flex-end" width="100%">
            <Stack
              direction={breakpointDownMD ? "column" : "row"}
              width="100%"
              alignItems="center"
              spacing={breakpointDownMD ? 0 : 2}
              justifyContent={breakpointDownMD ? "flex-start" : "flex-end"}>
              <Stack spacing={1.5} width="100%" alignItems="flex-end">
                <AIOSearchComponent
                  placeholder={t("ads.searchPlaceholder")}
                  fullWidth
                  width={breakpointDownMD ? "100%" : undefined}
                  onChange={(value) => adsService.setFilters({ search: value })}
                />
              </Stack>
              <Stack direction={"row"} spacing={2} alignItems={"center"}>
                <Stack direction="row" flexWrap={breakpointDownMD ? "wrap" : undefined} justifyContent={breakpointDownMD ? "center" : "flex-end"}>
                  <AIOSelectComponent
                    items={AdsSortField.selectItems}
                    handleChange={(value) => adsService.setSort({ field: value })}
                    maxWidth="180px"
                    value={sort.field}
                  />
                </Stack>
                <Stack direction="row" flexWrap={breakpointDownMD ? "wrap" : undefined} justifyContent={breakpointDownMD ? "center" : "flex-end"}>
                  <AIOSelectComponent
                    items={AdsFilterArchived.selectItems}
                    handleChange={(value) => adsService.setFilters({ isArchived: value })}
                    maxWidth="180px"
                    value={filters.isArchived}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            spacing={2}
            overflow={breakpointDownLG ? undefined : "auto"}
            className={breakpointDownLG && sessionQuery.role !== UserRoleEnum.ADMIN ? undefined : "scrollable"}
            py={breakpointDownLG && sessionQuery.role !== UserRoleEnum.ADMIN ? "0px" : "30px"}
            px="10px"
            ref={scrollableAdsRef}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography fontSize="16px" fontWeight="700">
                {adsLoading ? "-" : ""}
                {!adsLoading &&
                  (filters.isArchived
                    ? t(`ads.amountArchived`, { count: adsPaginationData.total ?? 0 })
                    : t(`ads.amount`, { count: adsPaginationData.total ?? 0 }))}
              </Typography>
            </Stack>
            <Box>
              <Grid container spacing={3}>
                <InfiniteScrollContainer
                  nextPageHandler={handleNextPage}
                  itemsPerPage={itemsPerPage}
                  componentType="Grid"
                  listItems={ads.map((ad) => (
                    <VirtualScrollItem
                      key={ad.id}
                      height={200}
                      children={<AdCard ad={ad} handleClick={() => navigate(`${ad.id}/${AdRoutes.DETAIL}`)} />}
                    />
                  ))}
                />
              </Grid>
            </Box>
            <Stack direction="row" width="100%" justifyContent="center" mt="20px" height={"80px"} alignItems="center">
              {adsLoading && <CircularProgress size={30} />}
            </Stack>
            <BackToTopButton onClick={backToTop} posRight={breakpointDownSM ? "25px" : "60px"} />
          </Stack>
        </Stack>
      </Stack>
      {openConfyAdsModal && (
        <ConfyAdsModal
          handleClose={() => setOpenConfyAdsModal(false)}
          handleSuccess={() => searchAds({ filters, sort, page: currentPage, take: itemsPerPage })}
        />
      )}
      {openAddAdModal && (
        <ManageAdModal
          selectedAffiliate={filters.affiliateIds?.length === 1 ? filters.affiliateIds[0] : undefined}
          isCreation
          handleClose={() => setOpenAddAdModal(false)}
        />
      )}
      <PublishModalsManager isOpen={openPublishModalManager} onClose={() => setOpenPublishModalManager(false)} singular={false} />
    </Stack>
  );
};

export default Ads;
