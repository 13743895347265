import { useState } from "react";
import { Stack } from "@mui/material";
import DatePickerComponent from "@components/input/DatePicker.component";
import HourPickerComponent from "@components/input/HourPicker.component";
import { useTranslation } from "react-i18next";
import { StyledTextDescribeModal, StyledTitleModal } from "./ConfirmPublishAds.modal";

interface PublishLaterModalProps {
  singular: boolean;
  onDateChange: (date: Date | null) => void;
  onTimeChange: (time: Date | null) => void;
}

const PublishLaterModal = ({ singular, onDateChange, onTimeChange }: PublishLaterModalProps) => {
  const { t } = useTranslation();

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<Date | null>(null);

  const handleDateChange = (date: Date | undefined) => {
    setSelectedDate(date || null);
    onDateChange(date || null);
  };

  const handleTimeChange = (time: Date | undefined) => {
    setSelectedTime(time || null);
    onTimeChange(time || null);
  };

  return (
    <Stack alignItems="center" justifyContent="center" paddingY=".8rem" width="100%" gap="30px">
      <img src="/images/icon_clock.svg" alt="Publish Later" style={{ width: "44px", height: "44px" }} />
      <StyledTitleModal>{t("ads.details.modals.publish.later.title")}</StyledTitleModal>
      {singular ? (
        <StyledTextDescribeModal>{t("ads.details.modals.publish.later.descriptionAd")}</StyledTextDescribeModal>
      ) : (
        <StyledTextDescribeModal>{t("ads.details.modals.publish.later.descriptionAds")}</StyledTextDescribeModal>
      )}
      <Stack direction="column" spacing={2} justifyContent="space-between" alignItems="center" width="100%">
        <Stack width="50%">
          <DatePickerComponent
            disablePast={true}
            format="DD/MM/YYYY"
            handleChange={handleDateChange}
            placeholder={t("ads.details.modals.publish.later.date_placeholder")}
            value={selectedDate ?? undefined}
          />
        </Stack>
        <HourPickerComponent
          format="HH:mm"
          handleChange={handleTimeChange}
          placeholder={t("ads.details.modals.publish.later.time_placeholder")}
          value={selectedTime ?? undefined}
        />
      </Stack>
    </Stack>
  );
};

export default PublishLaterModal;
