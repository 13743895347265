import React, { useState, useEffect } from "react";
import { IconButton, Pagination, PaginationItem, Stack, Tooltip, Typography, alpha, styled } from "@mui/material";
import { DataGrid, GridColDef, GridFooterContainer } from "@mui/x-data-grid";
import { useObservable } from "@ngneat/react-rxjs";
import { useTranslation } from "react-i18next";

import { Colors } from "@constants/colors.constant";
import { adsQuery, adsService, MultiDiffusionAdStatusDetails } from "@store/ads";
import useMultiDiffusionAdStore, { MultiDiffusionModalEnum } from "@store/multiDiffusionAd/multiDiffusionAd.store";
import { useSnackbar } from "notistack";
import { finalize } from "rxjs";
import dayjs from "dayjs";

export enum MultiDiffusionActionsEnum {
  EDIT = "EDIT",
  VIEW = "VIEW",
  LINK = "LINK",
  REFRESH = "REFRESH",
  CLOSE = "CLOSE",
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: "none !important",
  flex: 1,
  height: "100%",
  maxHeight: "calc(100vh - 200px)",
  "& .MuiDataGrid-virtualScroller": {
    overflowX: "auto",
  },
  "& .MuiDataGrid-columnHeaders": {
    border: `1px solid rgba(34, 34, 64, 0.05)`,
    backgroundColor: Colors.white,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 300,
  },
  "& .MuiDataGrid-withBorder": {
    borderRight: "none !important",
  },
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-row": {
    minHeight: "61px !important",
    backgroundColor: Colors.greyCardBg,
  },
  "& .MuiDataGrid-footerContainer": {
    border: "none",
    justifyContent: "flex-end",
  },
  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
    outline: "none !important",
  },
  "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus": {
    outline: "none !important",
  },
  "& .headerCell": {
    display: "flex",
    alignItems: "center",
    padding: "8px 16px",
    color: Colors.black,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },
  "& .bodyCell": {
    display: "flex",
    alignItems: "center",
    padding: "8px 16px",
    color: Colors.black,
    lineHeight: 1.25,
    fontWeight: 300,
  },
  "& .bodyCell.bold": {
    fontWeight: 500,
  },
  "& .MuiPaginationItem-root:not([disabled]) svg": {
    fill: Colors.primary,
  },
  "& .MuiDataGrid-cell": {
    minHeight: "61px !important",
    fontWeight: 300,
    whiteSpace: "pre-wrap !important",
    "& .MuiButton-root": {
      marginLeft: "-12px",
      padding: "10px 15px !important",
    },
  },
  "& .MuiDataGrid-cell > div": {
    maxWidth: "100%",
  },
}));

const ITEMS_PER_PAGE = 25;

const MultiDiffusionAdsTable: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [multiDiffusionAds] = useObservable(adsQuery.multiDiffusionAds$);
  const [multiDiffusionAdsFilters] = useObservable(adsQuery.multiDiffusionAdsFilters$);

  const { t } = useTranslation();
  const { setModalTypeOpened, setManageMultiDiffusionAdSelected } = useMultiDiffusionAdStore();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    adsService
      .getMultiDiffusionAds(multiDiffusionAdsFilters, currentPage, ITEMS_PER_PAGE)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({ error: (err) => enqueueSnackbar(err.text, err.options) });
  }, [currentPage, enqueueSnackbar, multiDiffusionAdsFilters]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("multiDiffusion.tab.adName"),
      minWidth: 300,
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Typography variant="body2" sx={{ maxWidth: "100%" }}>
          {params.row.name}
        </Typography>
      ),
    },
    {
      field: "reference",
      headerName: t("multiDiffusion.tab.adReference"),
      minWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Typography
          noWrap
          sx={{
            maxWidth: "100%",
            fontWeight: 300,
            fontSize: "14px",
          }}>
          {params.row.reference}
        </Typography>
      ),
    },
    {
      field: "affiliate.name",
      headerName: t("multiDiffusion.tab.adCompany"),
      minWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Typography
          noWrap
          sx={{
            maxWidth: "100%",
            fontWeight: 300,
            fontSize: "14px",
          }}>
          {params?.row?.affiliateName ?? "-"}
        </Typography>
      ),
    },
    {
      field: "location",
      headerName: t("multiDiffusion.tab.adLocation"),
      minWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Typography
          noWrap
          sx={{
            maxWidth: "100%",
            fontWeight: 300,
            fontSize: "14px",
          }}>
          {params.row.location.charAt(0).toUpperCase() + params.row.location.slice(1).toLowerCase()}
        </Typography>
      ),
    },
    {
      field: "publicationDate",
      headerName: t("multiDiffusion.tab.adPublication"),
      minWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Typography
          sx={{
            maxWidth: "100%",
            fontWeight: 300,
            fontSize: "14px",
          }}>
          {params.row.publishedAt
            ? new Date(params.row.publishedAt).toLocaleDateString()
            : params.row.programedAt
            ? `${t("multiDiffusion.tab.adPublicationProgramedAt")} ${dayjs(params.row.programedAt).format("DD/MM/YYYY")}`
            : "-"}
        </Typography>
      ),
    },
    {
      field: "status",
      headerName: t("multiDiffusion.tab.adStatus.title"),
      minWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const statusDetail = MultiDiffusionAdStatusDetails.getStatusDetails(params.row.status);
        return (
          <Stack
            alignItems="center"
            justifyContent="center"
            p={1}
            sx={{
              backgroundColor: alpha(statusDetail?.color || Colors.primary, 0.15),
              borderRadius: "5px",
            }}>
            <Typography
              noWrap
              sx={{
                maxWidth: "100%",
                fontWeight: 400,
                fontSize: "14px",
                color: statusDetail?.color || Colors.primary,
              }}>
              {statusDetail?.statusLabel || ""}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      minWidth: 180,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const statusDetail = MultiDiffusionAdStatusDetails.getStatusDetails(params.row.status);
        return (
          <Stack direction="row" gap={1} alignItems="center" justifyContent="center">
            <Tooltip
              title={
                <Typography fontSize={12} fontWeight={500} textAlign="center">
                  {statusDetail?.actions.includes(MultiDiffusionActionsEnum.VIEW)
                    ? t("multiDiffusion.tooltips.view")
                    : t("multiDiffusion.tooltips.correct")}
                </Typography>
              }
              arrow
              placement="top">
              <span>
                <IconButton
                  onClick={() => {
                    setModalTypeOpened(MultiDiffusionModalEnum.CORRECT_AD);
                    setManageMultiDiffusionAdSelected(params.row);
                  }}
                  disabled={
                    !statusDetail?.actions.includes(MultiDiffusionActionsEnum.EDIT) && !statusDetail?.actions.includes(MultiDiffusionActionsEnum.VIEW)
                  }
                  sx={{
                    opacity:
                      statusDetail?.actions.includes(MultiDiffusionActionsEnum.EDIT) || statusDetail?.actions.includes(MultiDiffusionActionsEnum.VIEW)
                        ? 1
                        : 0.4,
                  }}>
                  <img
                    src={statusDetail?.actions.includes(MultiDiffusionActionsEnum.VIEW) ? "/images/icon_eye.svg" : "/images/icon_edit.svg"}
                    alt="edit"
                    height="18"
                    width="18"
                  />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              title={
                <Typography fontSize={12} fontWeight={500} textAlign="center">
                  {t("multiDiffusion.tooltips.link")}
                </Typography>
              }
              arrow
              placement="top">
              <span>
                <IconButton
                  onClick={() => window.open(process.env.REACT_APP_FRANCE_TRAVAIL_JOB_URL + params.row.refOffrePE, "_blank")}
                  disabled={!statusDetail?.actions.includes(MultiDiffusionActionsEnum.LINK)}
                  sx={{
                    opacity: statusDetail?.actions.includes(MultiDiffusionActionsEnum.LINK) ? 1 : 0.4,
                  }}>
                  <img src="/images/icon_link.svg" alt="link" height="18" width="18" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              title={
                <Typography fontSize={12} fontWeight={500} textAlign="center">
                  {t("multiDiffusion.tooltips.refresh")}
                </Typography>
              }
              arrow
              placement="top">
              <span>
                <IconButton
                  onClick={() => {
                    setManageMultiDiffusionAdSelected(params.row);
                    setModalTypeOpened(MultiDiffusionModalEnum.REFRESH_AD);
                  }}
                  disabled={!statusDetail?.actions.includes(MultiDiffusionActionsEnum.REFRESH)}
                  sx={{
                    opacity: statusDetail?.actions.includes(MultiDiffusionActionsEnum.REFRESH) ? 1 : 0.4,
                  }}>
                  <img src="/images/icon_refresh.svg" alt="refresh" height="18" width="18" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              title={
                <Typography fontSize={12} fontWeight={500} textAlign="center">
                  {t("multiDiffusion.tooltips.close")}
                </Typography>
              }
              arrow
              placement="top">
              <span>
                <IconButton
                  onClick={() => {
                    setManageMultiDiffusionAdSelected(params.row);
                    setModalTypeOpened(MultiDiffusionModalEnum.CLOSE_AD);
                  }}
                  disabled={!statusDetail?.actions.includes(MultiDiffusionActionsEnum.CLOSE)}
                  sx={{
                    opacity: statusDetail?.actions.includes(MultiDiffusionActionsEnum.CLOSE) ? 1 : 0.4,
                  }}>
                  <img src="/images/icon_closeAd.svg" alt="close" height="18" width="18" />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  const CustomFooter = () => {
    return (
      <GridFooterContainer>
        <Pagination
          color="primary"
          count={multiDiffusionAds.meta.pageCount}
          page={currentPage}
          onChange={(event, newPage) => {
            if (multiDiffusionAds.meta.pageCount >= newPage) setCurrentPage(newPage);
          }}
          renderItem={(item) => {
            if (item.type === "page" && item.selected)
              return (
                <Stack direction="row" alignItems="row" justifyContent="center">
                  <Typography color="primary" fontWeight={500}>
                    {item.page}
                  </Typography>
                  <Typography fontWeight={300}>/{multiDiffusionAds.meta.pageCount}</Typography>
                </Stack>
              );
            if (item.type !== "page" && item.type !== "end-ellipsis" && item.type !== "start-ellipsis") return <PaginationItem {...item} />;
          }}
        />
      </GridFooterContainer>
    );
  };

  return (
    <StyledDataGrid
      rows={multiDiffusionAds.data}
      loading={loading}
      rowsPerPageOptions={[]}
      pagination
      disableColumnMenu
      disableSelectionOnClick
      page={currentPage - 1}
      pageSize={multiDiffusionAds.meta.take}
      paginationMode="server"
      filterMode="server"
      headerHeight={35}
      rowHeight={61}
      rowCount={multiDiffusionAds.meta.itemCount}
      columns={columns}
      components={{
        Footer: CustomFooter,
      }}
    />
  );
};

export default MultiDiffusionAdsTable;
