import { alpha } from "@mui/material";

export const Colors: any = {
  primary: "#E8614D",
  lightPrimary: alpha("#E8614D", 0.1),
  lightPrimaryNoAlpha: "#FDF0EE",
  secondary: "#00022F",
  error: "#D0102D",
  lightError: alpha("#D0102D", 0.2),
  marine: "#283276",
  affiliate: "#71A5FA",
  grey: "#78788A",
  greyLight: "#8E8EA3",
  greyCardBg: "#F3F3F3",
  greyBg: "#F9F9F9",
  purple: "#7B7BD9",
  yellow: "#FFC145",
  lightYellow: "#FFF0C4",
  lightpurple: "#DCDCFF",
  lightBlue: "#C0E0FE",
  lightPink: "#FFDDDD",
  lightGreen: "#C4F5C0",
  lightGrey: "#818192",
  lighterPurple: `${alpha("#DCDCFF", 0.4)}`,
  green: "#7AD272",
  applicationsOrange: alpha("#FFCF6F", 0.18),
  orange: "#FD9B56",
  lightOrange: `${alpha("#f26726", 0.1)}`,
  gold: "#FFCF6F",
  background: "rgb(243, 243, 243)",
  lightBackground: "rgb(249, 249, 249)",
  avatarBackground: "rgb(229, 229, 232)",
  secondaryText: "rgba(34, 34, 64, 0.6)",
  black: "#000000",
  white: "#FFFFFF",
  mauve: "#7B7BD9",
  tooltipBackground: "rgba(0, 2, 47, 0.6)",
  twinin: "#F60125",
  lightMarine: `${alpha("#222240", 0.1)}`,
  linkedin: "#3572AB",
  multiDiffusion: "#283276",
  checkIcon: "#a5eb78",
  cardBorder: "#EFEFEF",
  fields: "#F3F3F3",
  // TODO: change this  name color
  consultCompany: "#F0F0FE",
  careerSite: {
    primary: "#FFD66E",
    secondary: "#AFCAFF",
    tertiary: "#E9624D",
  },
  personae: {
    lightPrimary: "#FFE0D1",
    lightOrange: "#FFF0C4",
    lightPurple: "#DCDCFF",
    lightGreen: "#C4F5C0",
    lightBlue: "#C0E0FE",
  },
  chart: {
    chartText: "#7F8097",
    chartBorder: "#7F8097",
    redDataSet: "#E13133",
    orangeDataSet: "#EE824B",
    purpleDataSet: "#7B7BD9",
    pinkDataSet: "#E680FC",
    yellowDataSet: "#F9CB48",
    greenDataSet: "#7AD272",
  },
  progress: {
    up: "#40CB55",
    down: "#FA5443",
  },
  stats: {
    green: "#C4F5C0",
    purple: "#DCDCFF",
    red: "#FFDDDD",
    orange: "#FFF0C4",
    blue: "#C0E0FE",
  },
  boostCard: {
    primaryText: "#00022F",
    secondaryText: "#8E8EA3",
    info: "#71A5FA",
    lightInfo: alpha("#71A5FA", 0.1),
    warning: "#FD9B56",
    lightWarning: alpha("#FD9B56", 0.1),
    succeed: "#7AD272",
    lightSucced: alpha("#7AD272", 0.1),
    error: "#E8614D",
    lightError: alpha("#E8614D", 0.1),
    lightGrey: alpha("#00022F", 0.07),
  },
  subscriptions: {
    freemium: "#7D7DDC",
    professional: "#FA6049",
    company: "#FFC042",
    employerBrand: "#7B7BD9",
    lightBackground: "#F6F6F6",
    darkBackground: "#242445",
    detailsEvenRow: "#FBFBFB",
    detailsOddRow: "#F8F8F8",
    currentOffers: "#A7A7B3",
    seeAll: "#4E4E66",
  },
  users: {
    MANAGER: alpha("#7676EE", 0.3),
    RESPONSIBLE: alpha("#E8614D", 0.3),
    BUYER: alpha("#FFCF6E", 0.3),
    listBackground: alpha("#A5A5B6", 0.07),
    border: "#E6E6EB",
  },
  prompts: {
    border: "#EFEFEF",
    btnBg: "rgb(34, 34, 64, 0.1)",
    toneBg: "rgba(232, 97, 77, 0.1)",
  },
  unipileInvitationStatus: {
    LIMIT_REACHED: "#71A5FA",
    ERROR: "#D0102D",
    PENDING_SEND: "#E8614D",
    PENDING_ACCEPTATION: "#FFC042",
    ACCEPTED: "#7AD272",
    DECLINED: "#7676EE",
  },

  twilioCallsStatus: {
    queued: "#FFB445",
    ringing: "#FFB445",
    "in-progress": "#FFB445",
    completed: "#A1DF99",
    busy: "#E8614D",
    failed: "#E8614D",
    "no-answer": "#E8614D",
    canceled: "#E8614D",
  },
};

export function isDark(hex: string): boolean {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  return yiq <= 128;
}
