import React, { ReactElement, ReactNode, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";
import { styled } from "@mui/material/styles";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import Typography from "@mui/material/Typography";
import { Colors } from "@constants/colors.constant";
import { alpha, Box, FormHelperText, InputAdornment, Stack, useMediaQuery, useTheme } from "@mui/material";
import { SelectItem } from "@components/input/Select.component";

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  return (
    <>
      {Array.isArray(data[index]) ? (
        <Stack direction="row" alignItems="center" component="li" {...data[index][0]} style={inlineStyle} spacing={1}>
          <Box>
            <Box
              sx={{
                backgroundColor: data[index][0]["aria-selected"] ? Colors.primary : undefined,
                border: `1px solid ${data[index][0]["aria-selected"] ? Colors.primary : Colors.greyLight}`,
                borderRadius: "50%",
                height: 16,
                width: 16,
              }}
            />
          </Box>
          <Typography noWrap fontWeight={data[index][0]["aria-selected"] ? "700" : "300"} lineHeight="1.25">
            {data[index][1].label}
          </Typography>
        </Stack>
      ) : (
        <Stack px="10px" style={inlineStyle} component="li" justifyContent="center">
          <Typography noWrap fontSize="15px" fontWeight="500" lineHeight="1.25">
            {data[index].groupLabel}
          </Typography>
        </Stack>
      )}
    </>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListBoxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: ReactElement[] = [];

  (children as ReactElement[]).forEach((item: ReactElement & { children?: ReactElement[] }) => {
    itemData.push({
      ...item.props.children[0].props.children,
      groupLabel: item.props.children[1].props.children?.[0]?.[1]?.data?.groupName,
    });
    itemData.push(...item.props.children[1].props.children.sort((a: any, b: any) => (a[1].value === item.props.children[0].props.children ? -1 : 1)));
  });
  itemData.shift(); // on enleve le premier groupe label qui contient favoris et all clients

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const itemSize = breakpointDownSM ? 50 : 30;

  const getHeight = () => {
    if (itemData.length > 8) {
      return 8 * itemSize;
    }
    return itemData.map(() => itemSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemData.length);

  return (
    <div ref={ref} className="scrollable">
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={() => itemSize}
          overscanCount={5}
          itemCount={itemData.length}>
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.paper}`]: {
    borderRadius: 15,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  [`& .${autocompleteClasses.endAdornment}`]: {
    right: "12px !important",
  },
  [`& .${autocompleteClasses.inputRoot}`]: {
    height: 35,
    padding: "0 36px 0 15px !important",
  },
  [`& .${autocompleteClasses.input}`]: {
    color: Colors.primary,
    fontSize: 14,
    fontWeight: 500,
    padding: "auto 8px !important",
    "&::placeholder": {
      opacity: 1,
    },
  },
}) as typeof Autocomplete;

interface MultiSelectWithSearchComponentProps {
  disabled?: boolean;
  handleChange?: (values: SelectItem[]) => void;
  items?: SelectItem[];
  loading?: boolean;
  multiSelectedLabel?: (count: number) => string;
  placeholder?: string;
  readOnly?: boolean;
  startIcon?: ReactNode;
  values?: SelectItem[];
  handleGroupBy?: (option: SelectItem) => string;
  error?: string;
}

const MultiSelectWithSearchComponent = (props: MultiSelectWithSearchComponentProps) => {
  const {
    disabled,
    handleChange,
    items = [],
    loading,
    multiSelectedLabel,
    placeholder,
    readOnly,
    startIcon,
    values = [],
    handleGroupBy,
    error = false,
  } = props;

  const [open, setOpen] = useState(false);

  const [inputValue, setInputValue] = useState("");

  const getFormattedValue = () => {
    if (!values.length) return placeholder;
    if (values.length === 1) return values.flatMap((v) => v.label).join("");
    if (!!multiSelectedLabel) return multiSelectedLabel(values.length);
    return values.flatMap((v) => v.label).join(", ");
  };

  return (
    <StyledAutocomplete
      fullWidth
      onOpen={() => setOpen(true)}
      onClose={() => {
        setOpen(false);
        setInputValue("");
      }}
      disableListWrap
      disableClearable
      multiple
      disabled={disabled}
      disableCloseOnSelect
      clearOnBlur={false}
      readOnly={readOnly}
      popupIcon={readOnly ? null : <img alt="" src="/images/arrow_dropdownlist_primary.svg" />}
      PopperComponent={StyledPopper}
      ListboxComponent={ListBoxComponent}
      getOptionLabel={(option: SelectItem) => option.label}
      isOptionEqualToValue={(option: SelectItem, value: SelectItem | string) =>
        typeof value === "string" ? option.value === value : option.value === value.value
      }
      loading={loading}
      options={loading ? [] : [...(placeholder ? [{ label: placeholder, value: "" }] : []), ...items]}
      onChange={(e, newValues, reason, details) => {
        if (details && !details.option?.value) {
          handleChange?.([]);
        } else {
          if (newValues.length > 1 && newValues[newValues.length - 1].data?.parentClientId !== newValues[newValues.length - 2].data?.parentClientId) {
            handleChange?.([newValues.pop()!].filter((v) => !!v));
          } else {
            handleChange?.((newValues as any[]).filter((v) => !!v));
          }
        }
      }}
      renderTags={() => null}
      limitTags={1}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            error={!!error}
            sx={{ backgroundColor: alpha(Colors.primary, 0.1), borderRadius: "7px" }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {startIcon && <InputAdornment position="start">{startIcon}</InputAdornment>}
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
          {error && <FormHelperText sx={{ color: Colors.error, transform: "translateY(-5px)" }}>{error}</FormHelperText>}
        </>
      )}
      onInputChange={(e, newValue, reason) => setInputValue(newValue)}
      inputValue={open && !readOnly ? inputValue : getFormattedValue()}
      value={values.length ? values : ([""] as any[])}
      renderOption={(props, option) => [props, option] as React.ReactNode}
      groupBy={handleGroupBy ? handleGroupBy : () => ""}
    />
  );
};

export default MultiSelectWithSearchComponent;
