import { alpha, Components } from "@mui/material";
import { Colors } from "@constants/colors.constant";

const button: Components = {
  MuiButton: {
    styleOverrides: {
      root: (props) => ({
        color: Colors.secondaryText,
        borderRadius: 15,
        ...(props.ownerState["aria-label"] === "modal"
          ? {
              borderRadius: 10,
            }
          : {}),
        ...(props.ownerState["aria-label"] === "linkedin"
          ? {
              backgroundColor: Colors.linkedin + " !important",
              color: Colors.white,
            }
          : {}),
        ...(props.ownerState["aria-label"] === "light"
          ? {
              backgroundColor: alpha(Colors.primary, 0.1),
              "& .MuiTypography-root": {
                color: Colors.primary + " !important",
              },
            }
          : {}),
        ...(props.ownerState["aria-label"] === "multiDiffusion"
          ? {
              backgroundColor: Colors.multiDiffusion + " !important",
              color: Colors.white,
            }
          : {}),
        padding: "11px 16px",
        textTransform: "none",
        "&:hover": {
          color: Colors.primary,
        },
      }),
      contained: (props) => ({
        backgroundColor: Colors.primary,
        boxShadow: "none",
        color: Colors.white,
        height: "min-content",
        "&.Mui-disabled": {
          backgroundColor: Colors.primary,
          color: alpha(Colors.white, 0.5),
        },
        ...(props.ownerState["aria-label"] === "title"
          ? {
              boxShadow: `0 8px 20px 0 ${alpha(Colors.primary, 0.5)}`,
            }
          : {}),
        ...(props.ownerState["aria-label"] === "glowing"
          ? {
              boxShadow: `0 8px 20px 0 ${alpha(Colors.primary, 0.5)}`,
              borderRadius: 10,
            }
          : {}),
        "&:hover": {
          backgroundColor: Colors.primary,
          color: Colors.white,
        },
      }),
    },
    variants: [
      {
        props: { color: "primary" },
        style: {
          color: Colors.primary,
        },
      },
    ],
  },
};

export default button;
