import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, Container, Grid, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useObservable } from "@ngneat/react-rxjs";

import { sessionQuery, sessionService } from "@store/session";
import { UserRoleEnum } from "@store/users";
import { adDetailsQuery, adDetailsService } from "@store/ads/details";
import { FeatureCreditsTypeEnum, subscriptionsQuery, SubscriptionTypeEnum } from "@store/subscriptions";
import { unipileService } from "@store/unipile";

import PotentialProfileCard from "@components/card/PotentialProfileCard.card";
import AIOButtonComponent from "@components/Button.component";
import CheckCreditsModal from "@components/modal/CheckCredits.modal";
import WrongSubscriptionModal from "@components/modal/WrongSubscription.modal";
import LinkedinInvitationModal from "@screens/auth/common/ads/components/LinkedinInvitation.modal";
import { PotentialProfilesRoutes } from "@utils/routes.utils";

const MAX_SELECTED_CARDS = 10;

const AdsPotentialProfilesListTab = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { adId } = useParams();
  const navigate = useNavigate();

  const [{ adsPotentialProfiles: potentialProfiles = [], loading: profilesLoading }] = useObservable(adDetailsQuery.potentialProfiles$);
  const [{ loading: unipileAccountStatusLoading }] = useObservable(sessionQuery.unipileAccountStatus$);

  const [selectedProfiles, setSelectedProfiles] = useState<string[]>([]);

  const [openLinkedinInvitation, setOpenLinkedinInvitation] = useState(false);

  const [checkUserCredits, setCheckUserCredits] = useState(false);
  const [checkSubscription, setCheckSubscription] = useState(false);

  const canAccessUnipileFeature = useMemo(() => {
    return sessionQuery.user?.role === UserRoleEnum.ADMIN || adDetailsQuery.managedByTwinin || subscriptionsQuery.sessionUserSubscriptionOngoing;
  }, []);

  useEffect(() => {
    if (!canAccessUnipileFeature) return;

    sessionService.checkUnipileAccountStatus().subscribe({
      error: (err: any) => enqueueSnackbar(err.text, err.options),
    });
  }, [canAccessUnipileFeature, enqueueSnackbar]);

  useEffect(() => {
    if (!adId) return;
    adDetailsService.getPotentialProfiles(adId).subscribe({
      error: (err: any) => enqueueSnackbar(err.text, err.options),
    });
  }, [adId, enqueueSnackbar]);

  const handleGeneratePotentialProfiles = useCallback((removeCredit?: boolean) => {
    if (!adId) return;
    const obs = adDetailsService.generatePotentialProfiles(adId, removeCredit).subscribe({
      error: (err: any) => enqueueSnackbar(err.text, err.options),
    });

    return () => obs.unsubscribe();
  }, [adId, enqueueSnackbar]);

  const handleConnectWithUnipileLinkedin = useCallback(() => {
    unipileService.getUnipileHostedAuthURL(window.location.href).subscribe({
      next: (response) => (window.location.href = response),
      error: (err: any) => enqueueSnackbar(err.text, err.options),
    });
  }, [enqueueSnackbar]);

  return (
    <>
      <Container sx={{ alignSelf: "center", overflow: "auto", height: "100%" }}>
        <Stack width="100%" justifyContent="center" alignItems="center">
          {!potentialProfiles.length && profilesLoading ? (
            <CircularProgress sx={{ alignSelf: "center" }} size={20} />
          ) : potentialProfiles.length ? (
            <Stack width="100%">
              <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between" mb="30px">
                <AIOButtonComponent
                  title={t("ads.details.potentialProfilesTab.regeneratePotentialProfiles")}
                  variant="contained"
                  disabled={profilesLoading}
                  onClick={() => {
                    if (sessionQuery.user?.role === UserRoleEnum.ADMIN || adDetailsQuery.managedByTwinin) {
                      handleGeneratePotentialProfiles();
                    } else {
                      setCheckUserCredits(true);
                    }
                  }}
                />
                <Stack direction="row" alignItems="center" spacing="20px">
                  {!unipileAccountStatusLoading && canAccessUnipileFeature && !sessionQuery.isConnectedToUnipile && (
                    <Box width="fit-content" whiteSpace="nowrap">
                      <AIOButtonComponent
                        ariaLabel="linkedin"
                        title={t("ads.details.potentialProfilesTab.loginWithUnipile")}
                        variant="contained"
                        onClick={() => handleConnectWithUnipileLinkedin()}
                      />
                    </Box>
                  )}
                  {(!canAccessUnipileFeature || (!unipileAccountStatusLoading && sessionQuery.isConnectedToUnipile)) && (
                    <AIOButtonComponent
                      ariaLabel="linkedin"
                      title={t("ads.details.potentialProfilesTab.inviteSelectedWithLinkedin") + (!!selectedProfiles.length ? ` (${selectedProfiles.length}/${MAX_SELECTED_CARDS})` : '')}
                      variant="contained"
                      disabled={unipileAccountStatusLoading || (canAccessUnipileFeature && !selectedProfiles.length)
                        || (canAccessUnipileFeature && !sessionQuery.isConnectedToUnipile)
                      }
                      onClick={() => {
                        if (!canAccessUnipileFeature) return setCheckSubscription(true);
                        setOpenLinkedinInvitation(true);
                      }}
                    />
                  )}
                </Stack>
              </Stack>
              <Box>
                <Grid container spacing="30px">
                  {potentialProfiles.map((profile) => {
                    const selected = selectedProfiles.includes(profile.id);

                    return (
                      <Grid item xs={12} sm={6} md={4} lg={3} key={profile.id}>
                        <PotentialProfileCard
                          disabledSelection={selectedProfiles.length >= MAX_SELECTED_CARDS}
                          handleSelect={!unipileAccountStatusLoading && canAccessUnipileFeature && sessionQuery.isConnectedToUnipile ? () => {
                            if (selected) {
                              setSelectedProfiles((state) => state.filter((pId) => pId !== profile.id));
                            } else {
                              setSelectedProfiles((state) => state.concat(profile.id));
                            }
                          } : undefined}
                          selected={selected}
                          profile={profile} />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
              <Stack direction="row" width="100%" justifyContent="center" mt="20px" height={"80px"} alignItems="center">
                {profilesLoading && <CircularProgress size={30} />}
              </Stack>
            </Stack>
          ) : (
            <AIOButtonComponent
              title={t("ads.details.potentialProfilesTab.generatePotentialProfiles")}
              variant="contained"
              ariaLabel="modal"
              onClick={() => {
                if (sessionQuery.user?.role === UserRoleEnum.ADMIN || adDetailsQuery.managedByTwinin) {
                  handleGeneratePotentialProfiles();
                } else {
                  setCheckUserCredits(true);
                }
              }}
            />
          )}
        </Stack>
      </Container>
      {openLinkedinInvitation && !!adId && (
        <LinkedinInvitationModal
          adId={adId}
          potentialProfiles={potentialProfiles.filter((p) => selectedProfiles.includes(p.id))}
          handleClose={() => setOpenLinkedinInvitation(false)}
          handleSuccess={() => {
            navigate(`../${PotentialProfilesRoutes.LINKEDIN_INVITATIONS}`);
          }}/>
      )}
      {checkUserCredits && (
        <CheckCreditsModal
          handleCreditsValidated={() => {
            setCheckUserCredits(false);
            handleGeneratePotentialProfiles(true);
          }}
          handleCloseCheck={() => setCheckUserCredits(false)}
          type={FeatureCreditsTypeEnum.POTENTIAL_PROFILES}
        />
      )}
      {checkSubscription && (
        <WrongSubscriptionModal
          description={t('ads.details.potentialProfilesTab.notForFreemium')}
          title={t('ads.details.potentialProfilesTab.inviteSelectedWithLinkedin')}
          subscriptionsRequired={[SubscriptionTypeEnum.PROFESSIONAL, SubscriptionTypeEnum.COMPANY]}
          handleClose={() => setCheckSubscription(false)}
        />
      )}
    </>
  );
}

export default AdsPotentialProfilesListTab;